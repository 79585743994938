import { XMarkIcon } from "@heroicons/react/24/outline";
import { InformationCircleIcon } from "@heroicons/react/16/solid";
import MainButton from "../buttons/MainButton";

interface CalloutProps {
    message: string;
    actionLabel?: string;
    variant?: 'info' | 'warning' | 'success' | 'error';
    onActionClick?: () => void;
    onClose?: () => void;
    className?: string;
    expandedStyle?: boolean;
    customIcon?: React.ReactNode;
    mainButtonLabel?: string;
    mainButtonOnClick?: () => void;
}

const getVariantStyles = (variant: CalloutProps['variant'] = 'info') => {
    const styles = {
        info: {
            outerBg: 'bg-gradient-to-br from-main-gradient-first to-main-gradient-second',
            innerBg: 'bg-purple-100',
            text: 'text-slate-800',
            icon: 'text-indigo-500',
            link: 'text-blue-700 hover:text-blue-600',
            border: 'border-blue-400',
        },
        warning: {
            outerBg: 'bg-yellow-50',
            innerBg: 'bg-yellow-50',
            text: 'text-yellow-700',
            icon: 'text-yellow-400',
            link: 'text-yellow-700 hover:text-yellow-600',
            border: 'border-yellow-400',
        },
        success: {
            outerBg: 'bg-green-50',
            innerBg: 'bg-green-50',
            text: 'text-green-700',
            icon: 'text-green-400',
            link: 'text-green-700 hover:text-green-600',
            border: 'border-green-400',
        },
        error: {
            outerBg: 'bg-red-50',
            innerBg: 'bg-red-50',
            text: 'text-red-700',
            icon: 'text-red-400',
            link: 'text-red-700 hover:text-red-600',
            border: 'border-red-400',
        },
    };
    return styles[variant];
};

export const Callout: React.FC<CalloutProps> = ({
                                                    message,
                                                    actionLabel = 'klikk her',
                                                    variant = 'info',
                                                    onActionClick,
                                                    onClose,
                                                    className = '',
                                                    expandedStyle = false,
                                                    customIcon,
                                                    mainButtonLabel,
                                                    mainButtonOnClick,
                                                }) => {
    const styles = getVariantStyles(variant);

    const handleClose = (e: React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        onClose?.();
    };

    return (
        <div
            className={`relative rounded-lg p-1 ${styles.outerBg} ${className}`}
        >
            <div
                className={`flex ${styles.innerBg} py-5 px-4 rounded-md ${expandedStyle ? 'shadow-lg' : ''}`}
            >
                {onClose && (
                    <button
                        type="button"
                        onClick={handleClose}
                        className={`absolute right-2 top-2 hover:${styles.innerBg} rounded-full p-1 transition-colors`}
                    >
                        <XMarkIcon className="w-6 h-6" />
                    </button>
                )}
                <div className={`flex ${expandedStyle ? 'items-start' : 'items-center'}`}>
                    {customIcon || <InformationCircleIcon className={`h-6 w-6 ${styles.icon} ${expandedStyle ? 'mt-1 mr-3' : 'mr-2'}`} />}
                    <div className="flex-1">
                        <p className={`${styles.text} ${expandedStyle ? 'mb-3' : ''}`}>{message}</p>
                        {actionLabel !== undefined && actionLabel !== '' && (
                            <div className={`${expandedStyle ? 'flex justify-center' : 'inline'}`}>
                                <button
                                    type="button"
                                    onClick={onActionClick}
                                    className={`font-medium underline ${styles.link}`}
                                >
                                    {actionLabel}
                                </button>
                            </div>
                        )}
                        {mainButtonLabel && mainButtonOnClick && (
                            <div className="mt-4 flex justify-start">
                                <MainButton onClickEvent={mainButtonOnClick}>
                                    {mainButtonLabel}
                                </MainButton>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};