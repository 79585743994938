import React, {useState} from "react";
import HorizontalStepper, {Step} from "../../components/steppers/HorizontalStepper";
import {DownloadShopifyThemeStep} from "./components/DownloadShopifyThemeStep";
import {ProcessingStep} from "./components/ProcessingStep";
import {UploadUpdatedThemeToShopifyStep} from "./components/UploadUpdatedThemeToShopifyStep";
import {useNavigate} from "react-router";
import {useParams, useSearchParams} from "react-router-dom";
import Heading1 from "../../components/text/Heading1";

const UpdateThemePage = () => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams()
    const [jobId, setJobId] = useState<string | undefined>(undefined);

    const [currentStep, setCurrentStep] = useState(0);
    const [steps, setSteps] = useState<Step[]>([
        {
            id: "1",
            name: "Last ned tema fra Shopify",
        },
        {
            id: "2",
            name: "Tema behandles",
        },
        {
            id: "3",
            name: "Last opp oppdatert tema",
        },
    ]);

    const nextPressed = () => {
        if (currentStep === steps.length - 1) {
            navigate(searchParams.get("redirectUrl") || "/")
            return
        }

        setCurrentStep(currentStep + 1)
    }

    const onFailed = () => {
        setCurrentStep(0)
        setJobId(undefined)
    }


    return (
        <div className={"container mx-auto px-5 my-5 max-w-6xl"}>
            <Heading1 className={"mt-14 pb-10 md:mt-0"}>Oppdater tema</Heading1>
            <HorizontalStepper
                steps={steps}
                currentStep={currentStep}
            >
                <DownloadShopifyThemeStep
                    onNextPressed={nextPressed}
                    visible={currentStep === 0}
                    setJobId={setJobId}
                />
                <ProcessingStep
                    onFailed={onFailed}
                    onNext={nextPressed}
                    visible={currentStep === 1}
                    jobId={jobId}
                />
                <UploadUpdatedThemeToShopifyStep
                    onNextPressed={nextPressed}
                    jobId={jobId}
                    visible={currentStep === 2}
                />
            </HorizontalStepper>
        </div>
    )
}

export default UpdateThemePage