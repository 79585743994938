import React from "react";
import Heading2 from "../../components/text/Heading2";
import PowerUpGridElement from "./components/PowerupGridElement";
import {PowerUpDTO} from "../../types/PowerUpDTO";
import {usePowerUpsProvider} from "../../providers/PowerUpsProvider";
import {powerUpCanBeCustomized} from "./PowerUpMapper";
import Heading3 from "../../components/text/Heading3";
import PowerUpFeedbackPopup from "./PowerUpFeedbackPopup";
import {useStoreProvider} from "../../providers/StoreProvider";
import Callout from "../../components/text/Callout";
import {LightBulbIcon} from "@heroicons/react/24/outline";
import {Link} from "react-router-dom";


const PowerUpsPage = () => {
    const {store} = useStoreProvider();
    const {powerUps, customizePowerUp, disablePowerUp, enablePowerUp} = usePowerUpsProvider()

    const enablePowerupPressed = async (powerUpDto: PowerUpDTO) => {
        if (powerUpCanBeCustomized(powerUpDto)) {
            if (!powerUpDto.enabled) {
                await enablePowerUp(powerUpDto)
            }

            customizePowerUp(powerUpDto)
        }else {
            if (powerUpDto.enabled) {
                disablePowerUp(powerUpDto)
            }else {
                enablePowerUp(powerUpDto)
            }
        }
    }

    return (
        <div className="container mx-auto px-10 py-5">
            <Heading2 className="text-left text-4xl mt-3">Powerups</Heading2>
            <p className="text-gray-500 text-left text-md mt-2">
                Gi nettbutikken din superkrefter - med våre "powerups" kan du superenkelt teste konverteringsmetoder.
            </p>
            <div className="border-b border-gray-200 my-5 mb-10"></div>

            {store && !store?.themeRelease && (
                <div className={"w-full max-w-[600px] mx-auto"}>
                    <Callout className={"mb-10"}>
                        <div className={"flex flex-row items-center"}>
                            <span className={"flex gap-4 "}>
                                <LightBulbIcon className={"w-6 text-hover-color"} /> <Link to={"/themes"} className={"mr-0 pr-0 underline"}>Last ned et tema</Link>
                            </span>
                            <span>
                                &nbsp;for å ta i bruk power-ups
                            </span>
                        </div>
                    </Callout>
                </div>
            )}

            <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 2xl:grid-cols-4 gap-6">
                {powerUps?.map(powerUp => (
                    <PowerUpGridElement
                        powerUpDTO={powerUp}
                        onButtonClick={() => enablePowerupPressed(powerUp)}
                    />
                ))}
            </div>
            <div className={"text-center mt-20"}>
                <Heading3 className={""}>Hvilke powerups vil du se neste?</Heading3>
                <PowerUpFeedbackPopup />
            </div>

        </div>
    );
};

export default PowerUpsPage;
