import React, {useEffect} from "react";
import {PopupComponent} from "../../../components/modals/PopupComponent";
import MainButton from "../../../components/buttons/MainButton";
import Heading1 from "../../../components/text/Heading1";
import {Theme} from "@mui/material";
import {ThemeDTO} from "../../../types/ThemeDTO";
import {useStoreProvider} from "../../../providers/StoreProvider";

interface ThemeDetailsPopupProps {
    handleCancelClick: () => void;
    isVisible: boolean;
    handleDownloadClick: () => void;
    imageSrc: string;
    title: string;
    previewLink: string;
    selectedTheme: ThemeDTO;
}

const ThemeDetailsPopup: React.FC<ThemeDetailsPopupProps> = ({ handleCancelClick, isVisible, handleDownloadClick, imageSrc, title, previewLink, selectedTheme }) => {
    const {store} = useStoreProvider()
    const [buttonText, setButtonText] = React.useState<string>("Last ned");

    useEffect(() => {
        if (store?.themeRelease && store?.themeRelease.theme.id != selectedTheme.latestRelease.theme.id) {
            setButtonText("Bytt tema og last ned")
        }else if (store?.themeRelease && store?.themeRelease?.theme.id == selectedTheme.latestRelease.theme.id) {
            setButtonText("Last ned på nytt")
        }else {
            setButtonText("Last ned")
        }
    }, [store, selectedTheme]);

    return (
        <PopupComponent closePopup={handleCancelClick} isVisible={isVisible}>
            <div className="text-center">
                <Heading1 className={"mb-8"}>{title}</Heading1>
                <img src={imageSrc} alt={title} className="mb-8" />
                <div className={"flex flex-col"}>
                    <MainButton
                        className={"mb-4"}
                        onClickEvent={handleDownloadClick}
                    >

                        {buttonText}
                    </MainButton>
                </div>

            </div>
        </PopupComponent>
    );
};

export default ThemeDetailsPopup;
