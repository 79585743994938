import {FieldValues, FormProvider, useForm} from "react-hook-form";
import InputTextField from "../../components/input-fields/InputTextField";
import MainButton from "../../components/buttons/MainButton";
import DestructiveButton from "../../components/buttons/DestructiveButton";
import Callout from "../../components/text/Callout";
import axios from "axios";
import {getBackendURL} from "../../utils/EnvironmentsManager";
import toast from "react-hot-toast";
import {useEffect, useState} from "react";
import {usePowerUpsProvider} from "../../providers/PowerUpsProvider";

const CookieBannerForm = () => {
    const {closeCustomizeMenu, disablePowerUp} = usePowerUpsProvider()
    const methods = useForm()
    const [existingTitle, setExistingTitle] = useState<string | null | undefined>(undefined)
    const [existingDescription, setExistingDescription] = useState<string | null | undefined>(undefined)
    const [existingAcceptText, setExistingAcceptText] = useState<string | null | undefined>(undefined)

    const handleSubmit = (values:FieldValues) => {
        axios.post(getBackendURL() + "/api/v1/power-ups/cookie-banner/settings", {
            "title": values["title"],
            "description": values["description"],
            "acceptText": values["acceptText"]
        }).then(result => {
            toast.success("Lagret!");
        }).catch(error => {
            toast.error("En feil har dessverre oppstått")
        }).finally(() => {
            closeCustomizeMenu()
        })
    }

    useEffect(() => {
        axios.get(getBackendURL() + "/api/v1/power-ups/cookie-banner/settings").then(result => {
            setExistingTitle(result.data.title)
            setExistingDescription(result.data.description)
            setExistingAcceptText(result.data.acceptText)
        }).catch((error) => {
            // if the user does not have any settings
            if (error.response.status !== 404) {
                toast.error("En feil har oppstått");
            }
            setExistingTitle(null)
            setExistingDescription(null)
            setExistingAcceptText(null)
        })
    }, []);

    return (
        <>
            <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(handleSubmit)}>
                    <div className={"flex flex-col w-full mx-auto space-y-5 justify-center my-5"}>
                        {existingTitle !== undefined && (
                            <InputTextField
                                name={"title"}
                                type={"text"}
                                label={"Tittel"}
                                value={existingTitle || "Liker du cookies? 🍪"}
                                validation={{
                                    required: {value: true, message: 'Oppgi en tekst'},
                                    minLength: {value: 1, message: 'Teksten kan ikke være tom'},
                                    maxLength: {value: 100, message: 'Teksten kan ikke være lenger enn 100 tegn'}
                                }}
                            />
                        )}
                        {existingDescription !== undefined && (
                            <InputTextField
                                name={"description"}
                                type={"text"}
                                label={"Beskrivelse"}
                                value={existingDescription || "Vi bruker cookies for å forbedre din opplevelse på nettsiden."}
                                validation={{
                                    required: {value: true, message: 'Oppgi en tekst'},
                                    minLength: {value: 1, message: 'Teksten kan ikke være tom'},
                                    maxLength: {value: 100, message: 'Teksten kan ikke være lenger enn 100 tegn'}
                                }}
                            />
                        )}

                        {existingAcceptText !== undefined && (
                            <InputTextField
                                name={"acceptText"}
                                type={"text"}
                                label={"Aksepter knapp"}
                                value={existingAcceptText || "Den er grei"}
                                validation={{
                                    required: {value: true, message: 'Oppgi en tekst'},
                                    minLength: {value: 1, message: 'Teksten kan ikke være tom'},
                                    maxLength: {value: 25, message: 'Teksten kan ikke være lenger enn 25 tegn'}
                                }}
                            />
                        )}
                    </div>
                    <div className={"flex justify-center space-x-4"}>
                        <DestructiveButton onClickEvent={disablePowerUp}>
                            Deaktiver
                        </DestructiveButton>
                        <MainButton>
                            Lagre
                        </MainButton>
                    </div>
                </form>
            </FormProvider>
        </>
    )
}

export default CookieBannerForm;