import {Bars3Icon} from "@heroicons/react/24/outline";
import * as React from "react";
import {useState} from "react";
import {FormProvider, useForm} from "react-hook-form";
import MobileMenuPopover from "./MobileMenuPopover";
import {useCurrentUser} from "../../providers/UserProvider";
import {useStoreProvider} from "../../providers/StoreProvider";
import {useNavigate} from "react-router";

interface MobileNavBarProps {
    isOpen: boolean
    selectedSidebarItem: string;
    setSelectedSidebarItem: React.Dispatch<React.SetStateAction<string>>;
}

const MobileNavBar: React.FC<MobileNavBarProps> = ({
                                                       isOpen,
                                                       selectedSidebarItem,
                                                       setSelectedSidebarItem,
                                                   }) => {
    const [popoverOpen, setPopoverOpen] = useState(false);
    const {store} = useStoreProvider()
    const methods = useForm();
    const navigate = useNavigate()


    return (
        <FormProvider {...methods}>
            <form>
                <div
                    className="sticky top-0 z-40 w-screen flex items-center justify-center bg-white px-4 py-4 shadow-sm sm:px-6 lg:hidden md:hidden">
                    <img className="h-9 w-auto mr-auto" src="/Enthemed-icon-1.png" alt="Enthemed Logo"/>
                    <p className="text-md text-center text-gray-600 flex-grow">{store?.name}</p>
                    <button
                        type="button"
                        className="p-2.5 text-gray-700 ml-auto"
                        onClick={() => setPopoverOpen(!popoverOpen)}
                    >
                        <span className="sr-only">Toggle menu</span>
                        <Bars3Icon className="h-6 w-6" aria-hidden="true"/>
                    </button>
                </div>

                {popoverOpen && (
                    <MobileMenuPopover
                        isOpen={isOpen}
                        selectedSidebarItem={selectedSidebarItem}
                        setSelectedSidebarItem={setSelectedSidebarItem}
                        closePopover={() => setPopoverOpen(false)}
                    />
                )}
            </form>
        </FormProvider>
    );
};

export default MobileNavBar;
