import {FieldValues, FormProvider, useForm} from "react-hook-form";
import Heading2 from "../../components/text/Heading2";
import HeadingDescriptor from "../../components/text/HeadingDescriptor";
import MainButton from "../../components/buttons/MainButton";
import React, {useState} from "react";
import PasswordInput from "../../components/input-fields/PasswordInput";
import {useSearchParams} from "react-router-dom";
import axios from "axios";
import {getBackendURL} from "../../utils/EnvironmentsManager";
import {toastError} from "../../utils/ErrorHandlerUtils";
import {useNavigate} from "react-router";

const ChangePasswordPage = () => {
    const methods = useForm()
    const [password, setPassword] = useState('');
    const navigate = useNavigate()
    const [queryParameters] = useSearchParams()

    const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(e.target.value);
    };

    const onSubmit = (values: FieldValues) => {
        axios.put(getBackendURL() + "/api/v1/user/reset-password", {
            "token": queryParameters.get("token"),
            "password": values.password
        }).then((response) => {
            navigate("/login?success=Password changed successfully")
        }).catch((error) => {
            toastError(error)
        });
    };

    return (
        <>
            <FormProvider {...methods} >
                <div className="flex min-h-full flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8">
                    <div className="sm:mx-auto sm:w-full sm:max-w-md">
                        <img
                            className="mx-auto h-10 w-auto"
                            src="/enthemed-1.png"
                            alt="Enthemed"
                        />
                    </div>
                    <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]">
                        <div className="bg-white px-6 py-12 shadow sm:rounded-lg sm:px-12">
                            <form className="space-y-4" onSubmit={methods.handleSubmit(onSubmit)}>
                                <Heading2 className={"text-center"}>
                                    Nullstill ditt passord
                                </Heading2>
                                <HeadingDescriptor>Skriv inn et nytt passord under for å endre passordet ditt.</HeadingDescriptor>
                                <PasswordInput
                                    style={{width: "100%"}}
                                    name={"password"}
                                    label="Nytt passord"
                                    value={password}
                                    onValueChange={handlePasswordChange}
                                />
                                <div>
                                    <MainButton className={"w-full"}>Endre passord</MainButton>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </FormProvider>
        </>
    )
}
export default ChangePasswordPage;