import {ReactNode} from "react";

interface HeadingDescriptorInterface {
    children: ReactNode;
    className?: string;
    leftAligned?: boolean
    withoutMargin?: boolean
}

const HeadingDescriptor = ({children, leftAligned, className, withoutMargin}:HeadingDescriptorInterface) => {
    return (
        <div className={"text-lg text-slate-600 " + (withoutMargin || " mb-10 ") + (leftAligned ? " text-left " : " text-center ") + className}>
            {children}
        </div>
    )
}

export default HeadingDescriptor