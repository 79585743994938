import React from 'react';
import Heading1 from "../../components/text/Heading1";
import HeadingDescriptor from "../../components/text/HeadingDescriptor";
import Heading2 from "../../components/text/Heading2";

const PrivacyPolicy = () => {
    return (
        <>
            <img
                className="mx-auto h-10 w-auto mb-8 mt-16"
                src="/enthemed-1.png"
                alt="Enthemed"
            />
            <div className="bg-white p-12 shadow-lg rounded-md sm:mx-auto sm:max-w-4xl my-10">

                <Heading1>PERSONVERN</Heading1>
                <HeadingDescriptor>
                    Sist oppdatert: 1. september
                </HeadingDescriptor>

                <Heading2>Personopplysninger</Heading2>
                <HeadingDescriptor leftAligned={true}>
                    Når du registrerer deg eller handler på Enthemed.no lagrer vi navn, e-postadresse og annen nødvendig informasjon. Dette er nødvendig for å tilby deg vårt produkt, samt annen oppfølging. Vi er pålagt å oppbevare informasjonen i forbindelse med regnskapsføring, avgiftshåndtering og eventuell returhåndtering. Denne historikken slettes ikke. Vi oppbevarer av sikkerhetshensyn også IP-adressen som er benyttet for å registrere bestillingen din.
                </HeadingDescriptor>

                <Heading2>Betaling med kort</Heading2>
                <HeadingDescriptor leftAligned={true}>
                    Kortnummer oppbevares ikke utover det som er nødvendig for å sikre effektiv håndtering av eventuelle problemer med belastning, oppheving av reservasjon og kreditering.
                </HeadingDescriptor>

                <Heading2>Hva er cookies og hva brukes de til?</Heading2>
                <HeadingDescriptor leftAligned={true}>
                    Enthemed.no benytter informasjonskapsler (cookies), som de fleste andre nettsider, og i henhold til norsk lovgivning. En cookie er data som lagres i din nettleser for at nettsiden skal kjenne deg igjen fra side til side og besøk til besøk.
                </HeadingDescriptor>

                <Heading2>Hva slags informasjon lagres?</Heading2>
                <HeadingDescriptor leftAligned={true}>
                    Enthemed.no bruker såkalte førsteparts-cookies for at du skal få en grunnleggende nettbutikk funksjonalitet. Formålet med førsteparts-cookies er å kjenne igjen hvem du er slik at du får riktige priser og enklere innlogging. Vi kan på denne måten huske varene i handlevognen din og hvor du er på siden. Blokkerer du disse førsteparts-cookiene vil ikke siden vår fungere.
                </HeadingDescriptor>

                <Heading2>Slik kan du unngå cookies</Heading2>
                <HeadingDescriptor leftAligned={true}>
                    Om du ønsker å unngå bruk av cookies kan du stille inn din nettleser til å ikke akseptere cookies automatisk. Se nettleserens hjelpesider for innstillinger. Vær oppmerksom på at om du velger å utelukke cookies, vil flere av funksjonene på Enthemed.no ikke fungere.
                </HeadingDescriptor>

                <Heading2>Personopplysninger til tredjepart</Heading2>
                <HeadingDescriptor leftAligned={true}>
                    Enthemed.no selger ikke personopplysninger til tredjepart, og vi bytter eller videreformidler heller ikke slik informasjon med tredjepart. Utenforstående kan bare få innsyn i opplysningene hvis dette er nødvendig for å utføre bestemte tjenester for Enthemed.no for å levere tjenester for deg. I slike tilfeller inngås avtaler for å ivareta informasjonssikkerheten, i tillegg vil Enthemed.no alltid bestemme hvordan behandlingen av opplysningene skal foregå. Enthemed.no ved styrets leder er ansvarlig for behandlingen av personopplysninger som beskrevet ovenfor.
                </HeadingDescriptor>

                <Heading2>Sikker plattform</Heading2>
                <HeadingDescriptor leftAligned={true}>
                    Vår nettbutikk benytter en sikker plattform. E-handelsplattformen gjør det mulig for oss å selge våre produkter til deg. All data er lagret i sikre lagringsmedier, databaser og i den generelle applikasjonen e-handelsplattformen. Dine data blir lagret på en sikker server bak en sikker brannmur.
                </HeadingDescriptor>

                <Heading2>Spørsmål om personvern</Heading2>
                <HeadingDescriptor leftAligned={true}>
                    Ta kontakt med vårt kundesenter eller send oss en e-post på kontakt@enthemed.no.
                </HeadingDescriptor>
            </div>
        </>
    );
};

export default PrivacyPolicy;
