import React, { useEffect, useState } from 'react';
import { FieldValues, RegisterOptions, useFormContext } from 'react-hook-form';
import { TextField } from '@mui/material';

interface InputDateFieldProps {
    name: string;
    label: string;
    value?: string | Date | null;
    required?: boolean;
    style?: React.CSSProperties;
    validation?: RegisterOptions<FieldValues, string>;
    onChange?: (value: string) => void;
    min?: string; // Updated to string to directly use date format (YYYY-MM-DD)
    allowPastDates?: boolean; // New prop to control if past dates are allowed
}

const InputDateField = (props: InputDateFieldProps) => {
    const { register, setValue: setFormValue, formState: { errors } } = useFormContext();
    const [fieldValue, setFieldValue] = useState<string | Date | null | undefined>(props.value || null);

    const getCurrentDate = () => {
        const now = new Date();
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, "0");
        const day = String(now.getDate()).padStart(2, "0");
        return `${year}-${month}-${day}`;
    };

    useEffect(() => {
        setFieldValue(props.value || null);
    }, [props.value]);

    useEffect(() => {
        setFormValue(props.name, fieldValue || undefined);
    }, [fieldValue, props.name, setFormValue]);

    const { onChange, ...rest } = register(props.name, props.validation);

    return (
        <>
            <TextField
                style={{ marginTop: 20, ...props.style }}
                error={!!errors[props.name]}
                label={props.required ? `${props.label}*` : props.label}
                variant="outlined"
                type="date"
                value={fieldValue || ''} // Set empty string if no value
                InputLabelProps={{ shrink: true }}
                onChange={(e) => {
                    const selectedDate = e.target.value;
                    const minDate = props.min || getCurrentDate();

                    // Check if past dates are allowed
                    if (!props.allowPastDates && new Date(selectedDate) < new Date(minDate)) {
                        setFieldValue(minDate);
                        e.target.value = minDate; // Update input value to min date
                    } else {
                        setFieldValue(selectedDate);
                    }
                    props.onChange && props.onChange(selectedDate);
                    onChange(e);
                }}
                required={props.required}
                inputProps={{
                    min: props.allowPastDates ? undefined : (props.min || getCurrentDate()), // Set min date dynamically based on allowPastDates
                }}
                {...rest}
            />
        </>
    );
};

export default InputDateField;
