import React, {useEffect, useState} from "react";
import {FormControl, InputAdornment, InputLabel, OutlinedInput} from "@mui/material";
import {FieldValues, RegisterOptions, useFormContext} from "react-hook-form";
import ErrorText from "./ErrorText";

interface InputPercentFieldProps {
    name: string
    label: string
    value?: number | null
    required?: boolean
    placeholder?: string;
    style?: React.CSSProperties
    validation?: RegisterOptions<FieldValues, string>
    onChange?: (value: number | null) => void;
    max?: number
}

const InputPercentField = (props:InputPercentFieldProps) => {
    const {register, setValue: setFormValue, formState: { errors }} = useFormContext()
    const [fieldValue, setFieldValue] = useState<number | null | undefined>(props.value);

    // @ts-ignore
    const isRequired = props.validation?.required && (props.validation?.required === true || props.validation.required.value === true);

    useEffect(() => {
        setFieldValue(props.value);
    }, [props.value]);

    useEffect(() => {
        setFormValue(props.name, fieldValue || undefined);
    }, [fieldValue, props.name, setFormValue]);

    // Destructure onChange from register
    const { onChange, ...rest } = register(props.name, props.validation);

    return (
        <FormControl style={{...props.style}}>
            <InputLabel htmlFor={props.name}>{props.label}</InputLabel>
            <OutlinedInput
                id={props.name}
                type={"number"}
                value={fieldValue || undefined}
                error={!!errors[props.name]}
                startAdornment={<InputAdornment position="start">%</InputAdornment>}
                label={isRequired ? `${props.label}*` : props.label}
                placeholder={props.placeholder}
                required={props.required}
                onChange={(e) => {
                    if (props.max && Number(e.target.value) > props.max) {
                        return
                    }
                    setFieldValue(Number(e.target.value));
                    props.onChange && props.onChange(Number(e.target.value));
                    onChange(e); // Call the onChange from register
                }}
                {...rest} // Spread the rest of the register properties
            />
            <ErrorText errors={errors} name={props.name}/>
        </FormControl>
    )
}

export default InputPercentField;