import React from 'react';

interface DestructiveButtonProps {
    onClickEvent?: ()=>void
    children?: any
    className?: string
    disabled?: boolean
}

const DestructiveButton = (props:DestructiveButtonProps) => {

    const buttonClicked = () => {
        if (!props.disabled) {
            props.onClickEvent && props.onClickEvent()
        }
    }

    return (
        <button
            type="button"
            onClick={buttonClicked}
            className={"flex transition-all bg-gradient-to-r from-red-500 to-red-600 text-white py-2 px-6 rounded-full shadow-sm hover:from-red-700 hover:to-red-800 " + props.className}>
            {props.children}
        </button>
    )
}

export default DestructiveButton;