import { FieldValues } from "react-hook-form";
import {MenuOption} from "../../components/input-fields/DropdownMenu";
import {ProductConfig} from "../../types/ProductConfig";


export const extractIdAndVariant = (suggestion: MenuOption): { id: string | null, variantId: string | null } => {
    if (!suggestion) return { id: null, variantId: null };
    const [id, variantId] = (suggestion.id as String).split("_");
    return { id, variantId };
};

export const findProductHandle = (productId: string | null, products: ProductConfig[]): string | null => {
    if (!productId) return null;
    const product = products.find(product => product.product.shopifyAdminAPIId.toString() === productId.toString());
    return product?.product.handle ?? null;
};

export const findGraphqlId = (productId: string | null, products: ProductConfig[]): string | null => {
    if (!productId) return null;
    const product = products.find(product => product.product.shopifyAdminAPIId.toString() === productId.toString());
    return product?.product.graphqlId ?? null;
};

export const createProductSpecifics = (values: FieldValues, products: ProductConfig[]): any[] => {
    return products.filter(p => p.checked).map(p => {
        const { id: suggestionId, variantId: suggestionVariantId } = extractIdAndVariant(values[p.product.shopifyAdminAPIId + "_suggestion"]);
        const suggestedProduct = products.find(suggested => suggested.product.shopifyAdminAPIId.toString() === suggestionId?.toString());
        return {
            sourceProductId: p.product.shopifyAdminAPIId,
            sourceProductGraphqlId: p.product.graphqlId,
            suggestedProductHandle: suggestedProduct?.product.handle ?? null,
            suggestedProductId: suggestionId ?? null,
            suggestedProductGraphqlId: suggestedProduct?.product.graphqlId ?? null,
            suggestedProductVariantId: suggestionVariantId ?? null,
            discount: values[p.product.shopifyAdminAPIId + "_discount"] ?? null
        };
    });
};