import Heading2 from "../../../components/text/Heading2";
import HeadingDescriptor from "../../../components/text/HeadingDescriptor";
import Spinner from "../../../components/loading/Spinner";
import {useEffect} from "react";
import axios from "axios";
import {getBackendURL} from "../../../utils/EnvironmentsManager";
import toast from "react-hot-toast";

interface ProcessingStepProps {
    onNext: () => void;
    visible: boolean
    jobId?: string
    onFailed: () => void
}

export const ProcessingStep = ({onNext, visible, jobId, onFailed}: ProcessingStepProps) => {

    useEffect(() => {
        let intervalId: NodeJS.Timeout;

        if (visible && jobId) {
            // Poll the job status every 3 seconds
            intervalId = setInterval(() => {
                axios.get(`${getBackendURL()}/api/v1/theme/job/${jobId}`).then(response => {
                    const {status} = response.data;
                    if (status === 'COMPLETED') {
                        onNext();
                    } else if (status === 'FAILED') {
                        toast.error("En feil har dessverre oppstått. Prøv igjen senere")
                        onFailed && onFailed()
                    }
                }).catch(error => {
                        toast.error("Klarte ikke hente oppdatert tema")
                }).finally(() => {
                    clearInterval(intervalId);
                })
            }, 3000); // Poll every 3 seconds
        }

        return () => {
            // Clean up the interval when the component unmounts or visibility changes
            if (intervalId) {
                clearInterval(intervalId);
            }
        };
    }, [visible, jobId, onNext]);

    return (
        <>
            {visible && (
                <div className={"flex flex-col justify-center items-center py-40"}>
                    <Heading2>Oppdaterer tema</Heading2>
                    <HeadingDescriptor leftAligned={true} withoutMargin={true} className={"text-center"}>Enthemed oppdaterer nå tema. Venligst
                        vent...</HeadingDescriptor>
                    <Spinner className={"mt-5"}/>
                </div>
            )}
        </>
    )
}