import React, { ReactNode } from 'react';

interface Heading3Props {
    children: ReactNode;
    className?: string;
}

const Heading3: React.FC<Heading3Props> = (props) => {
    return <h3
        className={"text-xl font-semibold text-slate-700" + props.className}>
        {props.children}
    </h3>;
}

export default Heading3;