import React, { useEffect, useRef, useState } from "react";
import { EllipsisVerticalIcon } from "@heroicons/react/24/solid";
import { AnimatePresence, motion } from "framer-motion";

export interface KebabMenuItem {
    label: string;
    onClickAction: () => void;
}

export interface KebabMenuProps {
    children?: React.ReactNode;
    menuItems: KebabMenuItem[];
}

const KebabMenu = ({ children, menuItems }: KebabMenuProps) => {
    const [visible, setVisible] = useState<boolean>(false);
    const menuRef = useRef<HTMLDivElement>(null);
    const anchorRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.key === "Escape") {
                setVisible(false);
            }
        };

        const handleClickOutside = (event: MouseEvent) => {
            if (menuRef.current && !menuRef.current.contains(event.target as Node) && anchorRef.current && !anchorRef.current.contains(event.target as Node)) {
                setVisible(false);
            }
        };

        if (visible) {
            window.addEventListener("keydown", handleKeyDown);
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            window.removeEventListener("keydown", handleKeyDown);
            document.removeEventListener("mousedown", handleClickOutside);
        }

        // Cleanup event listeners on component unmount
        return () => {
            window.removeEventListener("keydown", handleKeyDown);
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [visible]);

    const clickedMenuItem = (menuItem?: KebabMenuItem) => {
        menuItem?.onClickAction();
        setVisible(false);
    };

    return (
        <>
            <div
                ref={anchorRef}
            >
                <EllipsisVerticalIcon
                    onClick={() => setVisible(!visible)}
                    className={"w-5 h-5 cursor-pointer hover:scale-110 transition-all"}
                />
            </div>
            <AnimatePresence>
                {visible && (
                    <motion.div
                        ref={menuRef}
                        initial={{ opacity: 0, scale: 0.8 }}
                        animate={{ opacity: 1, scale: 1 }}
                        exit={{ opacity: 0, scale: 0.8 }}
                        transition={{ duration: 0.1 }}
                        className={"absolute bg-white shadow-xl right-0 rounded-lg border-[1px]"}
                    >
                        {menuItems.map((menuItem, index) => (
                            <React.Fragment key={index}>
                                {index !== 0 && <hr className={"border-[0.5px]"} />}
                                <div
                                    onClick={() => clickedMenuItem(menuItem)}
                                    className={
                                        "px-5 py-3 hover:bg-indigo-50 cursor-pointer max-w-[300px] truncate " +
                                        (index === 0 ? "rounded-t-lg " : "") +
                                        (index === menuItems.length - 1 ? "rounded-b-lg" : "")
                                    }
                                >
                                    {menuItem.label}
                                </div>
                            </React.Fragment>
                        ))}
                    </motion.div>
                )}
            </AnimatePresence>
        </>
    );
};

export default KebabMenu;