import React, {useEffect, useState} from "react";
import {Autocomplete, TextField} from "@mui/material";
import {FieldValues, RegisterOptions, useFormContext} from "react-hook-form";
import {MenuOption} from "./DropdownMenu";
import ErrorText from "./ErrorText";

interface AutocompleteDropdownProps {
    name: string
    label: string
    options: MenuOption[]
    defaultValue?: MenuOption | null;
    style?: React.CSSProperties
    validation?: RegisterOptions<FieldValues, string>
    onChange?: (value?: MenuOption | null) => void;
    actionButtonText?: string
    onActionButtonClicked?: () => void
}

const AutocompleteDropdown = (props: AutocompleteDropdownProps) => {
    const { register, setValue: setFormValue, setError: setErrorMessage, formState: { errors } } = useFormContext();

    const [convertedOptions, setConvertedOptions] = useState<MenuOption[]>([])
    const [selectedValue, setSelectedValue] = useState<MenuOption | null>(props.defaultValue || null)

    // @ts-ignore
    const isRequired = props.validation?.required && (props.validation?.required === true || props.validation.required.value === true);

    useEffect(() => {
        setSelectedValue(props.defaultValue || null)
    }, [props.defaultValue]);

    useEffect(() => {
        setFormValue(props.name, selectedValue || '');
    }, [selectedValue]);

    useEffect(() => {
        if (props.options) {
            let convertedOptions: MenuOption[] = []
            props.options.forEach((option: MenuOption, index) => {
                convertedOptions.push(option)
            })

            if (props.actionButtonText) {
                convertedOptions.push({title: props.actionButtonText, id: "action_button"})
            }
            setConvertedOptions(convertedOptions)
        }
    }, [props.actionButtonText, props.options]);

    const onAutocompleteChange = (event: React.ChangeEvent<{}>, value: MenuOption | null) => {
        if (value && value.id === "action_button") {
            props.onActionButtonClicked && props.onActionButtonClicked()
            setSelectedValue(null)
            props.onChange && props.onChange(null)
            return
        }
        setSelectedValue(value)
        props.onChange && props.onChange(value)
    }

    const { onChange, ...rest } = register(props.name, props.validation);

    return (
        <div className="flex flex-col space-y-4" style={props.style}>
            <Autocomplete
                options={convertedOptions}
                getOptionLabel={(option) => option.title}
                onChange={onAutocompleteChange}
                value={selectedValue || null}
                renderInput={(params) => {
                    return (
                        <>
                            <TextField
                                {...params}
                                label={isRequired ? `${props.label}*` : props.label}
                                error={!!errors[props.name]}
                            />
                            <ErrorText errors={errors} name={props.name} />
                        </>
                    )
                }}
            />
        </div>
    );
};

export default AutocompleteDropdown;