import {AnimatePresence, motion} from "framer-motion";
import MainButton from "../../components/buttons/MainButton";
import React, {useState} from "react";
import {Card} from "@mui/material";
import Heading1 from "../../components/text/Heading1";
import InputTextField from "../../components/input-fields/InputTextField";
import {FieldValues, FormProvider, useForm} from "react-hook-form";
import axios from "axios";
import {getBackendURL} from "../../utils/EnvironmentsManager";
import {toastError} from "../../utils/ErrorHandlerUtils";
import HeadingDescriptor from "../../components/text/HeadingDescriptor";
import toast from "react-hot-toast";
import {InformationCircleIcon} from "@heroicons/react/16/solid";
import VideoPopup from "../../VideoPopup";

interface InputStoreNameStepProps {
    onNextPressed: () => void
    stepVisible: boolean
}

const InputStoreNameStep = ({onNextPressed, stepVisible}:InputStoreNameStepProps) => {
    const [loading, setLoading] = useState(false)
    const methods = useForm()

    const validateShopName = () => {
        setLoading(true)

        let shopifyUrl = methods.getValues("shopifyUrl") as string
        let domainName = methods.getValues("domainName") as string


        if (!domainName) {
            toast.error("Du fylle ut domenenavnet ditt")
            setLoading(false)
            return;
        }

        // TODO: Here we must verify the store name.
        axios.post(getBackendURL() + "/api/v1/store/add", {
            "shopifyUrl": methods.getValues("shopifyUrl").toLowerCase(),
            "domainName": methods.getValues("domainName")
        }).then(result => {
            onNextPressed()
        }).catch(error => {
            if (error.response.status === 409) {
                toast.error("Denne nettbutikken er allerede registrert i Enthemed")
            }else if (error.response.status === 400) {
                toast.error("Shopify url eller domene url er ikke gyldig")
            }else if (error.response.status === 402) {
                toast.error("Abonnementet er dessverre ikke aktivt helt enda. Prøv igjen om 5 minutter")
            }else {
                toastError(error)
            }
        }).finally(() => {
            setLoading(false)
        })
    }

    return (
        <>
            <AnimatePresence>
                {stepVisible && (
                    <motion.div
                        initial={{ opacity: 0, scaleY: 0 }}
                        animate={stepVisible ? { opacity: 1, scaleY: 1, transition: {delay: 0.6, duration: 0.2} } : {}} // Animate only when dataLoaded is true
                        exit={{ opacity: 0, scaleY: 0}}
                        transition={{ duration: 0.1 }}>

                        <Card className={"px-10 py-10 rounded-xl"}>
                            <FormProvider {...methods}>
                                <Heading1>Koble til Shopify</Heading1>
                                <HeadingDescriptor withoutMargin={true}>For at du skal kunne bruke temaene må du registrere nettbutikken din</HeadingDescriptor>
                                <VideoPopup
                                    videoSrc={"https://fast.wistia.com/embed/medias/38br95zsoi"}
                                    infoText="Følg stegene i denne videoen for å registrere nettbutikken din i enthemed,"
                                />
                                <form className={"flex flex-col gap-y-4 mt-4"}>
                                    <InputTextField
                                        name={"shopifyUrl"}
                                        type={"text"}
                                        label={"Shopify URL"}
                                        placeholder={"eksempel.myshopify.com"}
                                        validation={{
                                            required: true,
                                            maxLength: 300,
                                            minLength: 30,
                                        }}
                                    />

                                    <InputTextField
                                        name={"domainName"}
                                        type={"text"}
                                        label={"Domene URL"}
                                        placeholder={"eksempel.no"}
                                        validation={{
                                            required: true,
                                            maxLength: 300,
                                            minLength: 30
                                        }}
                                    />

                                    <div className={"mt-5 w-full flex justify-center"}>
                                        <MainButton onClickEvent={validateShopName} showProcessingAnimation={loading}>Legg til nettbutikk</MainButton>
                                    </div>
                                </form>
                            </FormProvider>
                        </Card>

                    </motion.div>
                )}
            </AnimatePresence>
        </>
    )
}

export default InputStoreNameStep