import Heading2 from "../../../components/text/Heading2";
import HeadingDescriptor from "../../../components/text/HeadingDescriptor";
import MainButton from "../../../components/buttons/MainButton";
import React, {useState} from "react";
import {useThemes} from "../../../providers/ThemesProvider";
import {ThemeDTO} from "../../../types/ThemeDTO";
import Heading1 from "../../../components/text/Heading1";
import axios from "axios";
import {getBackendURL} from "../../../utils/EnvironmentsManager";
import toast from "react-hot-toast";
import {useStoreProvider} from "../../../providers/StoreProvider";

const UnknownThemePopup = () => {
    const {themes} = useThemes()
    const {reloadStores} = useStoreProvider()

    const [isLoading, setIsLoading] = useState(false)

    const [selectedTheme, setSelectedTheme] = useState<ThemeDTO | null>(null);

    const changeFromUnknownTheme = () => {
        setIsLoading(true)
        axios.post(getBackendURL() + "/api/v1/theme/change-from-unknown", {
            selectedTheme: selectedTheme?.id,
        }).then(result => {
            toast.success("Ditt valg av tema er lagret")
            reloadStores && reloadStores()
        }).catch(error => {
            toast.error("Noe gikk dessverre galt")
            setIsLoading(false)
        })
    }

    return (
        <div className={"w-screen absolute top-0 left-0 z-40 lg:left-5 lg:fixed lg:h-screen overflow-x-hidden"}>
            <div className={"fixed bg-white top-0 left-0 w-screen h-screen p-40 lg:hidden"} />
            <div
                className={"w-full h-full md:ml-8 lg:ml-0 flex bg-black p-10 bg-opacity-0 lg:bg-opacity-20 backdrop-blur md:justify-center md:items-center"}>
                <div
                    className={"w-full flex flex-col items-center bg-white p-10 rounded-xl shadow-xl md:max-w-[690px] lg:max-w-[890px]"}>
                    <Heading1>Vi har oppdatert temaene våre</Heading1>
                    <HeadingDescriptor>
                        Vi har lansert en ny versjon av temaene våre. For at du skal kunne oppdatere temaet ditt, må vi
                        vite hvilket tema du bruker. Dette trenger du bare gjøre en gang.
                    </HeadingDescriptor>

                    <strong className={"mb-2 text-xl"}>Velg tema du bruker</strong>
                    <div className={"grid gap-4 sm:grid-cols-2 lg:grid-cols-3"}>
                        {themes.map((theme) => (
                            <>
                                {theme && theme.id !== "unknown" && (
                                    <div
                                        className={"p-5 rounded-xl cursor-pointer hover:bg-indigo-50 " + (selectedTheme == theme ? "bg-indigo-50" : "")}
                                        onClick={() => setSelectedTheme(theme)}
                                    >
                                        <img src={theme.imageSrc} className={"w-full"}/>
                                        <div className={"text-lg text-center mt-2"}>{theme.title}</div>
                                    </div>
                                )}
                            </>
                        ))}
                    </div>

                    <MainButton
                        className={"mt-10"}
                        disabled={selectedTheme == null}
                        onClickEvent={changeFromUnknownTheme}
                        showProcessingAnimation={isLoading}
                    >
                        Velg tema
                    </MainButton>
                </div>
            </div>
        </div>
    )
}

export default UnknownThemePopup