import * as React from 'react';
import List from '@mui/material/List';
import {
    UsersIcon,
    HomeIcon,
    Cog8ToothIcon,
    LightBulbIcon,
    BriefcaseIcon,
    BanknotesIcon,
    BoltIcon,
    BuildingStorefrontIcon,
} from '@heroicons/react/24/outline';
import {Dispatch, SetStateAction, useEffect, useState} from 'react';
import SidebarListItemComponent, {SidebarItem} from './SidebarListItemComponent';
import {motion} from 'framer-motion';
import {IconButton} from '@mui/material';
import {ChevronLeftIcon, ChevronRightIcon} from '@heroicons/react/24/solid';
import {useStoreProvider} from "../../providers/StoreProvider";
import DropdownMenu, {MenuOption} from "../input-fields/DropdownMenu";
import {FormProvider, useForm} from "react-hook-form";
import {useNavigate} from "react-router";
import {useSearchParams} from "react-router-dom";

interface SidebarProps {
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
    selectedSidebarItem: string;
    setSelectedSidebarItem: Dispatch<SetStateAction<string>>;
    className?: string;
}

export default function Sidebar(props: SidebarProps) {
    const navigate = useNavigate();
    const [isMobile, setIsMobile] = useState<boolean>(false);
    const searchParams = useSearchParams()

    const [sidebarListItems, setSidebarListItems] = useState<SidebarItem[]>([
        {
            text: "Hjem",
            icon: <HomeIcon className="h-8 w-10 shrink-0"/>,
            link: "/",
            selected: () => window.location.pathname == "/"
        },
        {
            text: "Temaer",
            icon: <BuildingStorefrontIcon className="h-8 w-10 shrink-0"/>,
            link: "/themes",
            selected: () => window.location.pathname.includes("/themes")
        },
        {
            text: "Power-ups",
            icon: <BoltIcon className="h-8 w-10 shrink-0"/>,
            link: "/power-ups",
            selected: () => window.location.pathname.includes("/power-ups")
        }
    ])

    const [settingsItem, setSettingsItem] = useState<SidebarItem>(
        {
            text: "Innstillinger",
            icon: <Cog8ToothIcon className="h-8 w-10 shrink-0"/>,
            link: "/settings",
            selected: () => window.location.pathname.includes("/settings")
        }
    );

    function handleWindowSizeChange() {
        setIsMobile(window.innerWidth <= 768);
    }

    useEffect(() => {
        handleWindowSizeChange();
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        };
    }, []);

    const toggleDrawer = () => {
        props.setOpen(!props.open);
    };

    const onSidebarItemSelect = (item: SidebarItem) => {
        navigate(item.link)
    }

    return (
        <div className={props.className}>
            <motion.div
                initial={isMobile ? {width: 0} : {width: '80px'}}
                animate={props.open ? {width: '350px'} : isMobile ? {width: 0} : {width: '80px'}}
                transition={{duration: 0.2}}
                className="h-screen flex flex-col gap-y-5 overflow-y-auto bg-white shadow-md"
            >
                <div className="flex justify-center my-3">
                    {props.open ? (
                        <img className="h-9 w-auto mt-5" src="/Enthemed-1x.png"/>
                    ) : (
                        <img className="h-9 w-auto mt-5" src="/Enthemed-icon-1.png"/>
                    )}
                </div>
                <div className="flex justify-center">
                    <IconButton onClick={toggleDrawer}>
                        {props.open ? (
                            <ChevronLeftIcon className="h-6 w-auto"/>
                        ) : (
                            <ChevronRightIcon className="h-6 w-auto"/>
                        )}
                    </IconButton>
                </div>

                <List className="flex-1">
                    {sidebarListItems.map((sidebarItem) => (
                        <SidebarListItemComponent
                            sidebarItem={sidebarItem}
                            sidebarVisible={props.open}
                            onSelect={() => onSidebarItemSelect(sidebarItem)}
                        />
                    ))}
                </List>

                <div className="mt-auto">
                    <SidebarListItemComponent
                        sidebarItem={settingsItem}
                        sidebarVisible={props.open}
                        onSelect={() => onSidebarItemSelect(settingsItem)}
                    />
                </div>
            </motion.div>
        </div>
    )
        ;
}
