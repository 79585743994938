import {XMarkIcon} from "@heroicons/react/24/outline";
import MainButton from "../../../components/buttons/MainButton";
import {useCurrentUser} from "../../../providers/UserProvider";
import {useEffect, useState} from "react";
import {OnboardingStatus} from "../../../enums/OnboardingStatus";
import axios from "axios";
import {getBackendURL} from "../../../utils/EnvironmentsManager";
import {toast} from "react-hot-toast";
import {useStoreProvider} from "../../../providers/StoreProvider";
import SecondaryButton from "../../../components/buttons/SecondaryButton";
import { PopupComponent } from "../../../components/modals/PopupComponent";
import Spinner from "../../../components/loading/Spinner";

const OnboardingNotification = () => {
    const {currentUser, refreshUser} = useCurrentUser();
    const {store} = useStoreProvider();
    const [visible, setVisible] = useState<boolean>(false);
    const [showCalendly, setShowCalendly] = useState<boolean>(false);
    const [isCalendlyLoaded, setIsCalendlyLoaded] = useState<boolean>(false);

    useEffect(() => {
        if (currentUser && currentUser.onboardingStatus == OnboardingStatus.SKIPPED) {
            setVisible(true);
        }
    }, [currentUser]);

    useEffect(() => {
        if (showCalendly) {
            setIsCalendlyLoaded(false);
            const script = document.createElement('script');
            script.src = "https://assets.calendly.com/assets/external/widget.js";
            script.async = true;
            script.onload = () => {
                // Gi Calendly litt tid til å initialisere
                setTimeout(() => {
                    setIsCalendlyLoaded(true);
                }, 1000);
            };
            document.body.appendChild(script);

            return () => {
                document.body.removeChild(script);
                setIsCalendlyLoaded(false);
            };
        }
    }, [showCalendly]);

    const closePopup = () => {
        setVisible(false);
    };

    const closeCalendlyPopup = () => {
        setShowCalendly(false);
    };

    const finishNowPressed = () => {
        axios.post(getBackendURL() + "/api/v1/user/unskip-onboarding").then(result => {
            refreshUser && refreshUser();
        }).catch(error => {
            toast.error("En feil har dessverre oppstått");
        });
    };

    const onBoardingMeetingClicked = () => {
        setShowCalendly(true);
    };

    return (
        <>
            {visible && (
                <div className={"relative bg-gradient-to-br from-purple-100 to-indigo-100 rounded-xl p-4 mx-4 -mt-20 mb-10 border-main-color border-4 shadow-xl z-30 md:max-w-[600px] md:mx-auto"}>
                    <button
                        onClick={closePopup}
                        className={"absolute right-2 top-2"}
                    >
                        <XMarkIcon className={"w-6 h-6"}/>
                    </button>
                    <h1 className={"font-bold md:text-2xl md:mb-1"}>
                        Du har ikke fullført onboardingen
                    </h1>
                    <p>
                        For å kunne bruke Enthemed temaer og power-ups må du fullføre onboardingen. Dette sikrer at
                        Enthemed power-ups fungerer som de skal.
                    </p>
                    <div className={"flex justify-center gap-5 mt-5"}>
                        <SecondaryButton onClickEvent={onBoardingMeetingClicked}>
                            Book 1-1 onboarding møte
                        </SecondaryButton>
                        <MainButton onClickEvent={finishNowPressed}>
                            Fullfør nå
                        </MainButton>
                    </div>
                </div>
            )}

            <PopupComponent
                isVisible={showCalendly}
                closePopup={closeCalendlyPopup}
            >
                <div className="h-[700px] w-full">
                    {!isCalendlyLoaded && (
                        <div className="h-full w-full flex flex-col items-center justify-center">
                            <h2 className="text-2xl font-bold mb-4">Vent litt mens kalenderen laster</h2>
                            <Spinner className="mt-5" />
                        </div>
                    )}
                    <div
                        className="calendly-inline-widget"
                        data-url="https://calendly.com/eugeneandersen/enthemed-onboarding?primary_color=882ac1"
                        style={{
                            position: 'relative',
                            minWidth: '320px',
                            height: '700px',
                            width: '100%',
                            display: isCalendlyLoaded ? 'block' : 'none'
                        }}
                    />
                </div>
            </PopupComponent>
        </>
    );
};

export default OnboardingNotification;