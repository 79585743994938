import React, {ReactElement} from 'react';
import Spinner from "../loading/Spinner";
import ListItemIcon from "@mui/material/ListItemIcon";

interface SecondaryButtonProps {
    onClickEvent?: ()=>void
    children?: any
    showProcessingAnimation?:boolean
    className?: string
    icon?: ReactElement<any, any>
    doNotSubmit?: boolean
}

const SecondaryButton = (props:SecondaryButtonProps) => {
    let ClonedElementWithMoreProps = null
    if (props.icon) {
        ClonedElementWithMoreProps = React.cloneElement(
            props.icon!,
            { style: {color: "#FFFFFF"} }
        );
    }


    return (
        <button
            type={props.doNotSubmit ? "button" : undefined}
            className={`flex justify-center rounded-full bg-indigo-50 px-6 py-2 text-md font-semibold text-main-color shadow-sm hover:bg-indigo-200  ${props.showProcessingAnimation ? 'opacity-50 cursor-not-allowed' : ''}` + props.className}
            disabled={props.showProcessingAnimation}
            onClick={props.onClickEvent ? props.onClickEvent : undefined}>
            {props.icon} {props.icon ? <>&nbsp;&nbsp;</> : ""}
            {props.showProcessingAnimation ?
                <Spinner scale={25}/> : props.children
            }
        </button>
    )
}

export default SecondaryButton;