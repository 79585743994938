import React, { useState, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import axios from "axios";
import toast from "react-hot-toast";
import InputTextField from "../../../components/input-fields/InputTextField";
import MainButton from "../../../components/buttons/MainButton";
import { getBackendURL } from "../../../utils/EnvironmentsManager";
import { toastError } from "../../../utils/ErrorHandlerUtils";
import Heading2 from "../../../components/text/Heading2";
import { useCurrentUser } from "../../../providers/UserProvider";
import SettingsFragment from "./SettingsFragment";
import { useStoreProvider } from "../../../providers/StoreProvider";
import { PopupComponent } from "../../../components/modals/PopupComponent";
import Heading1 from "../../../components/text/Heading1";
import HeadingDescriptor from "../../../components/text/HeadingDescriptor";
import SecondaryButton from "../../../components/buttons/SecondaryButton";
import { Callout } from "../../../components/callout/Callout";
import { ShoppingBagIcon } from "@heroicons/react/24/outline";

interface FormInputs {
    shopifyUrl: string;
    domainName: string;
}

const StoreSettingsFragmentNode: React.FC = () => {
    const methods = useForm<FormInputs>();
    const [loading, setLoading] = useState<boolean>(false);
    const { currentUser, refreshUser } = useCurrentUser();
    const { reloadStores, showIntegrateStorePrompt } = useStoreProvider();
    const [isNotificationVisible, setIsNotificationVisible] = useState<boolean>(false);

    // State variables for popup handling
    const [isPopupVisible, setIsPopupVisible] = useState<boolean>(false);
    const [actionToConfirm, setActionToConfirm] = useState<() => void>(() => {});
    const [popupMessage, setPopupMessage] = useState<string>("");
    const [isShopifyUrlChanged, setIsShopifyUrlChanged] = useState<boolean>(false);

    useEffect(() => {
        const currentDomain = currentUser?.activeStore?.incomingHostname;
        const isShopifyDomain = currentDomain?.toLowerCase().includes('.myshopify.com') ?? false;
        setIsNotificationVisible(isShopifyDomain);
    }, [currentUser?.activeStore?.incomingHostname]);

    const checkShopifyUrlChanged = (newUrl: string): boolean => {
        const currentUrl = currentUser?.activeStore?.outgoingHostname;
        return Boolean(currentUrl && currentUrl.toLowerCase() !== newUrl.toLowerCase());
    };

    const handleOpenPopup = (action: () => void, message: string): void => {
        setActionToConfirm(() => action);
        setPopupMessage(message);
        setIsPopupVisible(true);
    };

    const handleConfirmAction = (): void => {
        setIsPopupVisible(false);
        actionToConfirm();
    };

    const handleDomainNotificationClick = (): void => {
        const domainInput = document.querySelector('input[name="domainName"]') as HTMLInputElement | null;
        if (domainInput) {
            domainInput.scrollIntoView({ behavior: 'smooth', block: 'center' });
            domainInput.focus();
        }

        const currentHostname = currentUser?.activeStore?.incomingHostname;
        if (currentHostname) {
            const suggestedDomain = currentHostname
                .replace('.myshopify.com', '.no')
                .replace(/^https?:\/\//, '');
            methods.setValue('domainName', suggestedDomain);
        }
    };

    const onStoreUpdate = (): void => {
        setLoading(true);

        const shopifyUrl = methods.getValues("shopifyUrl");
        const domainName = methods.getValues("domainName");

        const shopifyUrlRegex = /^[a-zA-Z0-9][a-zA-Z0-9.-]*\.myshopify\.com\/?$/;
        if (!shopifyUrlRegex.test(shopifyUrl.toLowerCase())) {
            toast.error("Ugyldig Shopify URL. URL må være på formatet '[navn].myshopify.com' og kan inneholde tall, bokstaver, punktum og bindestrek");
            setLoading(false);
            return;
        }

        if (!domainName) {
            toast.error("Du må fylle inn ditt domenenavn");
            setLoading(false);
            return;
        }

        const payload = {
            shopifyUrl: shopifyUrl.toLowerCase(),
            domainName: domainName,
        };

        axios
            .put(getBackendURL() + "/api/v1/store/update", payload)
            .then(() => {
                toast.success("Butikkinnstillingene ble oppdatert");
                if (reloadStores) reloadStores();
                if (refreshUser) refreshUser();

                // If Shopify URL was changed, show integrate store prompt
                if (isShopifyUrlChanged && showIntegrateStorePrompt) {
                    showIntegrateStorePrompt(
                        "Koble til Shopify på nytt",
                        <>
                            <span>Siden du endret Shopify URL må du koble til Shopify på nytt. Vi vil guide deg gjennom prosessen med å sette opp API-tilkoblingen.</span>
                        </>
                    );
                }
            })
            .catch((error) => {
                if (error.response && error.response.status === 400) {
                    toast.error("Shopify URL eller domenenavn er ikke gyldig");
                } else if (error.response && error.response.status === 409) {
                    toast.error("Denne butikken er allerede registrert i Enthemed");
                } else {
                    toastError(error);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const handleStoreUpdateClick = (): void => {
        const newShopifyUrl = methods.getValues("shopifyUrl");
        const isUrlChanged = checkShopifyUrlChanged(newShopifyUrl);
        setIsShopifyUrlChanged(isUrlChanged);

        if (isUrlChanged) {
            handleOpenPopup(
                onStoreUpdate,
                "Endring av Shopify URL vil fjerne alle API-integrasjoner og kreve ny oppsett. Dette vil påvirke butikkens funksjonalitet til API-integrasjonene er satt opp på nytt. Er du sikker på at du vil fortsette?"
            );
        } else {
            handleOpenPopup(
                onStoreUpdate,
                "Sørg for at du har skrevet inn riktig domene."
            );
        }
    };

    return (
        <div>
            {!currentUser?.activeStore ? (
                <div>
                    <Heading2 className="mb-5">Du har ikke en aktiv nettbutikk</Heading2>
                    <Callout
                        message="Her kan du administrere nettbutikken din, men du må legge til nettbutikken din først."
                        variant="info"
                        expandedStyle={true}
                        actionLabel=""
                        mainButtonLabel="Legg til nettbutikk"
                        mainButtonOnClick={() => showIntegrateStorePrompt && showIntegrateStorePrompt(
                            "Legg til nettbutikk",
                            <span>For å legge til en nettbutikk må du koble den til Shopify. Vi vil guide deg gjennom prosessen.</span>
                        )}
                    />
                </div>
            ) : (
                <div>
                    <Heading2 className="mb-5">Butikkinnstillinger</Heading2>
                    <FormProvider {...methods}>
                        <form>
                            <div className="flex flex-wrap gap-6">
                                {isNotificationVisible && (
                                    <Callout
                                        message="Vi ser at du bruker et .myshopify.com domene. For en mer profesjonell fremtoning anbefaler vi å bytte til et .no eller .com domene."
                                        actionLabel="Bytt domene nå"
                                        variant="info"
                                        expandedStyle={true}
                                        onActionClick={handleDomainNotificationClick}
                                        onClose={() => setIsNotificationVisible(false)}
                                    />
                                )}
                                <InputTextField
                                    style={{ width: "100%" }}
                                    name="shopifyUrl"
                                    type="text"
                                    value={currentUser?.activeStore?.outgoingHostname}
                                    label="Shopify URL"
                                    placeholder="eksempel.myshopify.com"
                                    validation={{
                                        required: true,
                                        maxLength: 300,
                                        minLength: 30,
                                    }}
                                />

                                <InputTextField
                                    style={{ width: "100%" }}
                                    name="domainName"
                                    type="text"
                                    value={currentUser?.activeStore?.incomingHostname}
                                    label="Domene URL"
                                    placeholder="eksempel.no"
                                    validation={{
                                        required: { value: true, message: "Domenenavn er påkrevd" },
                                    }}
                                />

                                <MainButton
                                    onClickEvent={handleStoreUpdateClick}
                                    className="my-6"
                                >
                                    Oppdater domene
                                </MainButton>
                            </div>
                        </form>
                    </FormProvider>

                    <PopupComponent isVisible={isPopupVisible} closePopup={() => setIsPopupVisible(false)}>
                        <div className="space-y-4">
                            <Heading1>Er du sikker?</Heading1>
                            <HeadingDescriptor>
                                {popupMessage}
                            </HeadingDescriptor>

                            <div className="flex justify-center gap-x-4 mt-6">
                                <SecondaryButton
                                    onClickEvent={() => setIsPopupVisible(false)}
                                >
                                    Tilbake
                                </SecondaryButton>
                                <MainButton
                                    onClickEvent={handleConfirmAction}
                                    disabled={loading}
                                >
                                    {loading ? "Oppdaterer..." : "Fortsett"}
                                </MainButton>
                            </div>
                        </div>
                    </PopupComponent>
                </div>
            )}
        </div>
    );
};

export const StoreSettingsFragment: SettingsFragment = {
    name: "Administrer nettbutikk",
    icon: <ShoppingBagIcon className="h-6 w-6 shrink-0 text-gray-400" />,
    fragmentNode: <StoreSettingsFragmentNode />,
};