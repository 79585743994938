import React, { useEffect, useState, useRef } from "react";
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { FieldValues, RegisterOptions, useFormContext } from "react-hook-form";
import ErrorText from "./ErrorText";
import SecondaryButton from "../buttons/SecondaryButton";

export interface MenuOption {
    title: string;
    id: number | string;
}

interface DropdownMenuProps {
    name: string;
    label: string;
    options: MenuOption[];
    defaultValue?: number | string;
    required?: boolean;
    style?: React.CSSProperties;
    validation?: RegisterOptions<FieldValues, string>;
    onChange?: (value?: MenuOption) => void;
    hideEmpty?: boolean;
    actionButtonText?: string;
    onActionButtonClicked?: () => void;
    icon?: React.ReactNode | string; // Update: Accept a ReactNode or a string for image paths
}

const DropdownMenu = (props: DropdownMenuProps) => {
    const { register, setValue: setFormValue, formState: { errors } } = useFormContext();
    const [selectedValue, setSelectedValue] = useState<number | string>(props.defaultValue || '');
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const anchorRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (props.defaultValue !== undefined) {
            setSelectedValue(props.defaultValue);
        }
    }, [props.defaultValue]);

    useEffect(() => {
        setFormValue(props.name, selectedValue);
    }, [selectedValue, props.name, setFormValue]);

    const handleActionButtonClick = () => {
        if (props.onActionButtonClicked) {
            props.onActionButtonClicked();
        }
    };


    const { onChange, ...rest } = register(props.name, props.validation);

    const handleChange = (event: SelectChangeEvent<number | string>) => {
        const value = event.target.value;
        setSelectedValue(value);

        // Call custom onChange with the MenuOption
        const option = props.options.find(option => option.id.toString() === value.toString());
        if (option && props.onChange) {
            props.onChange(option);
        }

        onChange(event as any);
    };

    const handleClose = () => {
        setIsDropdownOpen(false);
    };

    const handleToggle = () => {
        setIsDropdownOpen(prevOpen => !prevOpen);
    };

    return (
        <FormControl fullWidth style={{ marginTop: 20, ...props.style }} ref={anchorRef}>
            <InputLabel id={`${props.name}-label`}>{props.label}</InputLabel>
            <Select
                id={props.name}
                labelId={`${props.name}-label`}
                label={props.label}
                value={selectedValue}
                displayEmpty
                open={isDropdownOpen}
                onClose={handleClose}
                onOpen={handleToggle}
                onChange={handleChange}
                required={props.required}
                {...rest}
            >
                {!props.hideEmpty && <MenuItem value="">&nbsp;</MenuItem>}
                {props.options.map(option => (
                    <MenuItem key={option.id} value={option.id}>
                        <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                            {typeof props.icon === 'string' ? (
                                <img
                                    src={props.icon}
                                    alt="Icon"
                                    width={20}
                                    height={20}
                                    style={{ marginRight: 8 }}
                                />
                            ) : (
                                <span style={{ marginRight: 8 }}>{props.icon}</span>
                            )}
                            <span style={{
                                overflow: 'hidden',
                                whiteSpace: 'nowrap',
                                textOverflow: 'ellipsis',
                                flex: 1
                            }}>
                                {option.title}
                            </span>
                        </div>
                    </MenuItem>
                ))}
                {props.actionButtonText && (
                    <SecondaryButton
                        className={"mt-2 mx-auto"}
                        doNotSubmit={true}
                        onClickEvent={() => {
                            handleActionButtonClick();
                            handleClose();
                        }}
                    >
                        {props.actionButtonText}
                    </SecondaryButton>
                )}
            </Select>
            <ErrorText errors={errors} name={props.name} />
        </FormControl>
    );
};

export default DropdownMenu;
