import ConnectToShopifyStep from "../onboardings/ConnectToShopifyStep";
import IntegrateWithShopifyStep from "../onboardings/IntegrateWithShopifyStep";
import * as React from "react";
import ConfigureAppStep from "../onboardings/ConfigureAppStep";
import {useEffect} from "react";
import axios from "axios";
import {getBackendURL} from "../../utils/EnvironmentsManager";
import {useStoreProvider} from "../../providers/StoreProvider";
import Spinner from "../../components/loading/Spinner";
import Heading1 from "../../components/text/Heading1";
import SetUpAppStep from "../onboardings/SetUpAppStep";
import FinalStep from "../onboardings/FinalStep";
import {useNavigate} from "react-router";
import {useSearchParams} from "react-router-dom";
import {toast} from "react-hot-toast";
import {useCurrentUser} from "../../providers/UserProvider";

const IntegrateStorePage = () => {
    const {reloadStores, store} = useStoreProvider()
    const {refreshUser} = useCurrentUser()
    const navigate = useNavigate();
    const [step, setStep] = React.useState<number>(-1);

    const [searchParams, setSearchParams] = useSearchParams()

    useEffect(() => {
        checkLicenses()
    }, [store]);

    const checkLicenses = () => {
        // check if credentials already registered
        if (store?.credentialsForStoreRegistered) {
            navigateBack()
            return
        }
        setStep(0)
    };

    const userPressedNext = () => {
        setStep(step + 1);
    }

    const userPressedBack = () => {
        setStep(step - 1);
    }

    const userPressedFinalNext = () => {
        reloadStores && reloadStores()
        refreshUser && refreshUser()
        navigateBack()
        toast.success("Nettbutikken er nå integrert")
    }

    const navigateBack = () => {
        if (searchParams.has("redirectUri")) {
            navigate(searchParams.get("redirectUri")!)
        }else {
            navigate("/");
        }
    }

    return (
        <div className={"absolute -z-10 top-0 left-0 flex flex-col w-screen h-screen justify-center items-center px-10 md:pl-28 md:pr-8 lg:px-0"}>
            <div className={"flex flex-col items-center"}>
                {step == -1 && (
                    <>
                        <Heading1>Et øyeblikk</Heading1>
                        <Spinner className={"mt-5"} />
                    </>
                )}
                <IntegrateWithShopifyStep onNextPressed={userPressedNext} stepVisible={step == 0}/>
                <ConfigureAppStep onNextPressed={userPressedNext} onBackPressed={userPressedBack} stepVisible={step === 1}/>
                <SetUpAppStep onNextPressed={userPressedNext} onBackPressed={userPressedBack} stepVisible={step === 2}/>
                <ConnectToShopifyStep onNextPressed={userPressedFinalNext} onBackPressed={userPressedBack} stepVisible={step == 3}/>
            </div>
        </div>
    )
}

export default IntegrateStorePage;