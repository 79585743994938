import React from 'react';
import Heading1 from "../../components/text/Heading1";
import HeadingDescriptor from "../../components/text/HeadingDescriptor";
import Heading2 from "../../components/text/Heading2";

const TermsOfService = () => {
    return (
        <>
            <img
            className="mx-auto h-10 w-auto mb-8 mt-16"
            src="/enthemed-1.png"
            alt="Enthemed"/>
            <div className="bg-white p-12 shadow-lg rounded-md sm:mx-auto sm:max-w-4xl my-10">

                <Heading1>KJØPSVILKÅR</Heading1>
                <HeadingDescriptor>
                    Sist oppdatert: 1. september
                </HeadingDescriptor>
                <HeadingDescriptor leftAligned={true}>
                    Odderøyveien 40, 4610 Kristiansand<br/>
                    Selger: Enthemed<br/>
                    Kundeservice: kontakt@enthemed.no<br/>
                    Selger blir betegnet som: Enthemed, oss/våre eller vi. Kjøper blir betegnet som: kunde, du, deg,
                    ditt eller din.
                </HeadingDescriptor>

                <Heading2>Gjennomføring av handel</Heading2>
                <HeadingDescriptor leftAligned={true}>
                    Gjennomføring av handel her på www.enthemed.no gjøres veldig enkelt. Du oppretter en konto, og
                    velger et abonnement for kontoen din. Deretter fyller du ut navn, e-postadresse og
                    betalingsinformasjon.<br/>
                    Når du har gjennomført en handel hos oss, godkjenner du og samtykker våre vilkår. Ved handel av en
                    vare godkjenner du og samtykker produktets informasjon og merknader.
                </HeadingDescriptor>

                <Heading2>Personvern, sikkerhet og personopplysninger</Heading2>
                <HeadingDescriptor leftAligned={true}>
                    I forbindelse med bestillingen, godtar du at enthemed.no lagrer og bruker informasjonen din. Vi gjør
                    dette for å oppfylle våre forpliktelser overfor deg. Vi dokumenterer også all kommunikasjon vi har
                    med deg via e-post, for å kunne tilby tjenesten som du forventer av oss. Enthemed vil ikke avsløre
                    din personlige informasjon til tredjepart. Vi forbeholder oss retten til å bruke
                    e-post/telefonnummer for å sende markedsføring og nyhetsbrev.
                </HeadingDescriptor>

                <Heading2>Priser</Heading2>
                <HeadingDescriptor leftAligned={true}>
                    Alle våre priser er inkludert MVA med mindre annet oppgitt. Våre priser kan variere og det tas
                    derfor forbehold om prisendringer i forhold til markedet og andre faktorer. Det tas også forbehold
                    om skrive-/trykkfeil på vår nettside.
                </HeadingDescriptor>

                <Heading2>Betaling</Heading2>
                <HeadingDescriptor leftAligned={true}>
                    Betalingsmetodene vi har er: VISA, MasterCard, American Express og Paypal. Dersom du som kunde
                    betaler med kort vil kjøpesummen bli belastet samme døgn.<br/><br/>
                    Med mindre det kreves ved lov, er betalte abonnement ikke refunderbare. Visse refusjon forespørsler
                    for abonnementer kan vurderes av Enthemed på individuell basis og innvilges etter bedriftens eget
                    skjønn.<br/><br/>
                    Hvis betalingen din feiler med et aktivt abonnement, risikerer du at tilgang din til Enthemed
                    begrenses og stenges inntil utestående betaling er oppgjort. Din nettbutikk kan også begrenses for
                    kunder dersom du benytter en av våre temaer.
                </HeadingDescriptor>

                <Heading2>Abonnement</Heading2>
                <HeadingDescriptor leftAligned={true}>
                    Enthemed er kun tilgjengelig med et betalt abonnement. Du vil bli fakturert på forhånd på en
                    gjentakende og periodisk basis (som daglig, ukentlig, månedlig eller årlig), avhengig av hvilken
                    type abonnement du velger ved kjøp av abonnementet.<br/><br/>
                    Ved slutten av hver periode vil abonnementet ditt automatisk fornyes under nøyaktig samme
                    betingelser med mindre du kansellerer det, eller Enthemed kansellerer det.<br/><br/>
                    Du kan avbestille fornyelsen av abonnementet enten gjennom innstillingssiden på kontoen din eller
                    ved å kontakte vår kundeservice. Du vil ikke motta refusjon for beløp du allerede har betalt for
                    inneværende abonnementsperiode, og du vil ha tilgang til tjenesten til slutten av din nåværende
                    abonnementsperiode.<br/><br/>
                    Du skal oppgi nøyaktig og fullstendig faktureringsinformasjon inkludert fullt navn, adresse, stat,
                    postnummer, telefonnummer og en gyldig betalingsmåte.<br/><br/>
                    Dersom automatisk fakturering av en eller annen grunn ikke gjennomføres, vil Enthemed utstede en
                    elektronisk faktura som indikerer at du må betale manuelt innen en viss frist, med hele beløpet som
                    tilsvarer faktureringsperioden som angitt på fakturaen.<br/><br/>
                    Vi kan, etter eget skjønn og når som helst, endre våre priser. Enhver endring av pris vil tre i
                    kraft ved slutten av inneværende abonnementsperiode.<br/><br/>
                    Vi vil gi deg rimelig forhåndsvarsel om eventuelle endringer i pris slik at du får mulighet til å si
                    opp abonnementet før endringen trer i kraft.<br/><br/>
                    Din fortsatte bruk av tjenesten etter at endringen av abonnementsavgiften trer i kraft, innebærer at
                    du godtar å betale den endrede prisen.<br/><br/>
                    Vi kan, etter eget skjønn, tilby et abonnement med en gratis prøveperiode i en begrenset
                    tidsperiode.<br/><br/>
                    Du kan bli bedt om å oppgi faktureringsinformasjon for å registrere deg for gratis
                    prøveperiode.<br/><br/>
                    Hvis du oppgir faktureringsinformasjon ved registrering for gratis prøveperiode, vil du ikke bli
                    belastet av Enthemed før den gratis prøveperioden har utløpt. På den siste dagen av den gratis
                    prøveperioden, med mindre du har kansellert abonnementet, vil du automatisk bli belastet de
                    gjeldende priser for den typen abonnement du har valgt.<br/><br/>
                    Enthemed forbeholder seg retten til, når som helst og uten varsel, å (i) endre vilkårene og
                    betingelsene for tilbudet om gratis prøveperiode, eller (ii) kansellere slikt tilbud om gratis
                    prøveperiode.
                </HeadingDescriptor>

                <Heading2>Konto</Heading2>
                <HeadingDescriptor leftAligned={true}>
                    Når du oppretter en konto hos oss, må du til enhver tid oppgi informasjon som er nøyaktig,
                    fullstendig og oppdatert. Unnlatelse av å gjøre dette utgjør et brudd på vilkårene, noe som kan
                    resultere i umiddelbar avslutning av kontoen din på vår tjeneste.<br/><br/>
                    Du er ansvarlig for å beskytte passordet du bruker for å få tilgang til tjenesten og for alle
                    aktiviteter eller handlinger som skjer under ditt passord.<br/><br/>
                    Du godtar å ikke avsløre passordet ditt til noen tredjepart. Du må umiddelbart varsle oss hvis du
                    blir oppmerksom på et sikkerhetsbrudd eller uautorisert bruk av kontoen din.
                </HeadingDescriptor>

                <Heading2>Lisens</Heading2>
                <HeadingDescriptor leftAligned={true}>
                    Et aktivt abonnement hos Enthemed gir deg 1 lisens, som gir deg muligheten til å integrere en
                    nettbutikk med oss. For ytterligere nettbutikker du ønsker å bruke med Enthemed, krever det et
                    utvidet abonnement med en lisens per nettbutikk.<br/><br/>
                    Vi tillater våre brukere å bruke våre temaer under en programvarelisens, hvor vi forbeholder oss
                    eksklusive rettigheter til å bruke, endre og dele med hvem og hvordan vi ønsker. Du kan ikke på noe
                    tidspunkt distribuere temaer eller deler av det til andre for kommersielle formål. Hvis du gjør det,
                    bryter du vilkårene i denne avtalen.<br/><br/>
                    Uautorisert nedlasting, kringkasting, kopiering, distribusjon eller modifikasjon av Enthemed er ikke
                    tillatt under tydelig skriftlig tillatelse.<br/><br/>
                    Innholdet som er opprettet, importert, sendt inn eller lagret av brukeren er brukerens eiendom, og
                    brukeren er ansvarlig for å sikre retten til å bruke det.
                </HeadingDescriptor>

                <Heading2>Levering</Heading2>
                <HeadingDescriptor leftAligned={true}>
                    Produkter som tilbys på Enthemed.no er digitale produkter. Kjøperen kan benytte seg av innholdet og
                    tilhørende ressurser på alle sine enheter. Konto og innlogging er strengt personlig, og det er kun
                    du som kjøper som kan benytte deg av din konto. Deling av konto og/eller mistanke om deling av samme
                    konto uten skriftlig tillatelse kan føre til umiddelbar utestengelse uten refusjon.
                </HeadingDescriptor>

                <Heading2>Angrerett</Heading2>
                <HeadingDescriptor leftAligned={true}>
                    Ved nedlasting eller strømming av digitale enkelttjenester, vil angreretten falle bort så snart
                    tjenesten lastes ned, så sant den næringsdrivende har oppfylt lovens krav til forhåndsinformasjon og
                    prosedyrer.<br/><br/>
                    Digitale innholdstjenester ønskes normalt levert så snart avtalen er inngått. Under forutsetning av
                    at den næringsdrivende oppfyller krav fastsatt i loven, faller angreretten bort straks tjenesten tas
                    i bruk. Digitale innholdstjenester skiller seg på dette punktet fra levering av andre typer
                    tjenester, der angreretten består inntil tjenesten er ferdig levert. Angreretten faller bort så
                    straks du tar i bruk innholdet.
                </HeadingDescriptor>

                <Heading2>Reklamasjon</Heading2>
                <HeadingDescriptor leftAligned={true}>
                    Hvis det foreligger en mangel ved varen må kjøper innen rimelig tid etter at den ble oppdaget eller
                    burde ha blitt oppdaget, gi selger melding om at han eller hun vil påberope seg mangelen.<br/><br/>
                    Kjøper har alltid reklamert tidsnok dersom det skjer innen 2 mnd. fra mangelen ble oppdaget eller
                    burde blitt oppdaget. Reklamasjon kan skje senest to år etter at kjøper overtok varen.<br/><br/>
                    Dersom varen eller deler av den er ment å vare vesentlig lenger enn to år, er reklamasjonsfristen
                    fem år.<br/><br/>
                    Dersom varen har en mangel og dette ikke skyldes kjøperen eller forhold på kjøperens side, kan
                    kjøperen i henhold til reglene i forbrukerkjøpsloven kapittel 6 etter omstendighetene holde
                    kjøpesummen tilbake, velge mellom retting og omlevering, kreve prisavslag, kreve avtalen hevet
                    og/eller kreve erstatning fra selgeren.<br/><br/>
                    Reklamasjonen til selgeren bør skje skriftlig.
                </HeadingDescriptor>

                <Heading2>Retting eller omlevering</Heading2>
                <HeadingDescriptor leftAligned={true}>
                    Kjøperen kan velge mellom å kreve mangelen rettet eller levering av tilsvarende ting. Selger kan
                    likevel motsette seg kjøperens krav dersom gjennomføringen av kravet er umulig eller volder selgeren
                    urimelige kostnader. Retting eller omlevering skal foretas innen rimelig tid. Selger har i
                    utgangspunktet ikke rett til å foreta mer enn to avhjelpsforsøk for samme mangel.
                </HeadingDescriptor>

                <Heading2>Prisavslag</Heading2>
                <HeadingDescriptor leftAligned={true}>
                    Kjøper kan kreve et passende prisavslag dersom varen ikke blir rettet eller omlevert. Dette
                    innebærer at forholdet mellom nedsatt og avtalt pris svarer til forholdet mellom tingens verdi i
                    mangelfull og kontraktsmessig stand. Dersom særlige grunner taler for det, kan prisavslaget i stedet
                    settes lik mangelens betydning for kjøperen.
                </HeadingDescriptor>

                <Heading2>Heving</Heading2>
                <HeadingDescriptor leftAligned={true}>
                    Dersom varen ikke er rettet eller omlevert, kan kjøperen også heve kjøpet når mangelen ikke er
                    uvesentlig.
                </HeadingDescriptor>

                <Heading2>Endring av vilkår</Heading2>
                <HeadingDescriptor leftAligned={true}>
                    Vi har forbeholdt retten til å endre vilkårene på denne nettsiden.
                </HeadingDescriptor>

                <Heading2>Spørsmål om kjøpsvilkår</Heading2>
                <HeadingDescriptor leftAligned={true}>
                    Ta kontakt med vårt kundesenter eller send oss en e-post på kontakt@enthemed.no.
                </HeadingDescriptor>
            </div>
        </>
    );
};

export default TermsOfService;
