import {CheckIcon} from '@heroicons/react/24/solid'
import {ReactNode} from "react";

export interface Step {
    id: string,
    name: string,
}

interface HorizontalStepperProps {
    steps: Step[]
    currentStep: number,
    gotoStep?: (step: number) => void,
    children: ReactNode,
}

export default function HorizontalStepper({steps, currentStep, gotoStep, children}: HorizontalStepperProps) {
    return (
        <>
            <nav aria-label="Progress">
                <ol role="list"
                    className="divide-y divide-gray-300 rounded-md border border-gray-300 md:flex md:divide-y-0">
                    {steps.map((step, stepIdx) => (
                        <li key={step.name} className="relative md:flex md:flex-1">
                            {currentStep > stepIdx ? (
                                <div
                                    onClick={gotoStep ? () => gotoStep(stepIdx) : undefined}
                                    className={"group flex w-full items-center transition-all " + (gotoStep && "hover:cursor-pointer")}>
                                <span className="flex items-center px-6 py-4 text-sm font-medium">
                                  <span
                                      className={"flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full bg-main-color " + (gotoStep && "group-hover:bg-main-color")}>
                                    <CheckIcon aria-hidden="true" className="h-6 w-6 text-white"/>
                                  </span>
                                  <span className="ml-4 text-sm font-medium text-gray-900">{step.name}</span>
                                </span>
                                </div>
                            ) : currentStep === stepIdx ? (
                                <div
                                    aria-current="step"
                                    className="flex items-center px-6 py-4 text-sm font-medium"
                                >
                                <span
                                    className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-main-color">
                                  <span className="text-main-color">{step.id}</span>
                                </span>
                                    <span className="ml-4 text-sm font-medium text-main-color">{step.name}</span>
                                </div>
                            ) : (
                                <div className="group flex items-center">
                                <span className="flex items-center px-6 py-4 text-sm font-medium">
                                  <span
                                      className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-gray-300">
                                    <span className="text-gray-500">{step.id}</span>
                                  </span>
                                  <span className="ml-4 text-sm font-medium text-gray-500">{step.name}</span>
                                </span>
                                </div>
                            )}

                            {stepIdx !== steps.length - 1 ? (
                                <>
                                    {/* Arrow separator for lg screens and up */}
                                    <div aria-hidden="true"
                                         className="absolute right-0 top-0 hidden h-full w-5 md:block">
                                        <svg
                                            fill="none"
                                            viewBox="0 0 22 80"
                                            preserveAspectRatio="none"
                                            className="h-full w-full text-gray-300"
                                        >
                                            <path
                                                d="M0 -2L20 40L0 82"
                                                stroke="currentcolor"
                                                vectorEffect="non-scaling-stroke"
                                                strokeLinejoin="round"
                                            />
                                        </svg>
                                    </div>
                                </>
                            ) : null}
                        </li>
                    ))}
                </ol>
            </nav>
            <div className={"mt-5 bg-white p-10 rounded-xl shadow-xl mx-auto"}>
                {children}
            </div>
        </>
    )
}