import {AnimatePresence, motion} from "framer-motion";
import React, {useState} from "react";
import axios from "axios";
import {useNavigate} from "react-router";
import {toastError} from "../../../utils/ErrorHandlerUtils";
import {getBackendURL, isDevelopmentEnv} from "../../../utils/EnvironmentsManager";
import Heading1 from "../../../components/text/Heading1";
import HeadingDescriptor from "../../../components/text/HeadingDescriptor";
import MainButton from "../../../components/buttons/MainButton";

interface BuyExtraStoreLicenseStepProps {
    onNextPressed: () => void
    stepVisible: boolean
}

const BuyExtraStoreLicenseStep = ({onNextPressed, stepVisible}:BuyExtraStoreLicenseStepProps) => {
    const navigate = useNavigate();

    const [isLoadingPayment, setIsLoadingPayment] = useState(false)

    const startPaymentFlow = () => {
        setIsLoadingPayment(true)
        axios.post(getBackendURL() + "/api/v1/license/add-store").then(result => {
            console.log(result)
            if (result.data) {
                window.location.href = result.data
            }
        })
    }

    const skipStripeButtonClicked = () => {
        if (!isDevelopmentEnv()) {
            return
        }
        axios.post(getBackendURL() + "/api/v1/license/skip").then(result => {
            if (result.status === 200) {
                onNextPressed()
            }
        }).catch(error => {
            toastError(error)
        })
    }

    return (
        <>

            <AnimatePresence>
                {stepVisible && (
                    <motion.div
                        initial={{ opacity: 0, y: -50 }}
                        animate={stepVisible ? { opacity: 1, y: 0 } : {}} // Animate only when dataLoaded is true
                        exit={{ opacity: 0, y: 50 }}
                    >

                        <img src={"/undraw_happy_announcement_re_tsm0.svg"}
                             className="w-auto"
                             style={{ maxWidth: '300px', maxHeight: '400px'}}
                        />

                    </motion.div>
                )}
            </AnimatePresence>

            <AnimatePresence>
                {stepVisible && (
                    <motion.div
                        initial={{ opacity: 0}}
                        animate={stepVisible ? { opacity: 1} : {}} // Animate only when dataLoaded is true
                        exit={{ opacity: 0}}
                        transition={{ duration: 0.3 }}>
                        <Heading1 className={"mt-5"}>Én konto for <span className={"text-transparent bg-clip-text bg-gradient-to-b from-main-gradient-first to-main-gradient-second"}>alt</span>!</Heading1>
                        <HeadingDescriptor withoutMargin={true}>Legg til ekstra nettbutikker i ditt Enthemed abonnement for kun 199kr/mnd per stk.</HeadingDescriptor>
                    </motion.div>
                )}
            </AnimatePresence>

            <AnimatePresence>
                {stepVisible && (
                    <motion.div
                        initial={{ opacity: 0}}
                        animate={stepVisible ? { opacity: 1, transition: {delay: 1}} : {}} // Animate only when dataLoaded is true
                        exit={{ opacity: 0}}
                        transition={{ duration: 0.3 }}>
                        <MainButton className={"mt-5"} onClickEvent={startPaymentFlow} showProcessingAnimation={isLoadingPayment}>Neste</MainButton>
                        { isDevelopmentEnv() && (
                            <MainButton className={"mt-5"} onClickEvent={skipStripeButtonClicked} showProcessingAnimation={isLoadingPayment}>Skip stripe</MainButton>
                        )}
                    </motion.div>
                )}
            </AnimatePresence>
        </>
    )
}

export default BuyExtraStoreLicenseStep