import {PopupComponent} from "../../../components/modals/PopupComponent";
import {useState} from "react";
import {PowerUpDTO} from "../../../types/PowerUpDTO";
import Heading1 from "../../../components/text/Heading1";
import HeadingDescriptor from "../../../components/text/HeadingDescriptor";
import {getPreviewForPowerUp} from "../PowerUpMapper";

interface PowerUpPreviewProps {
    previewPowerUp?: PowerUpDTO
    cancelClicked: () => void
}

const PowerUpPreview = ({previewPowerUp, cancelClicked}: PowerUpPreviewProps) => {

    return (
        <>
            <PopupComponent
                closePopup={cancelClicked}
                isVisible={previewPowerUp != undefined}

            >
                <div className={"flex flex-col justify-center"}>
                    <Heading1>{previewPowerUp?.title}</Heading1>
                    <HeadingDescriptor>{previewPowerUp?.shortDescription}</HeadingDescriptor>
                    {previewPowerUp && (
                        <>
                            {getPreviewForPowerUp(previewPowerUp!.id)}
                        </>
                    )}
                </div>
            </PopupComponent>
        </>
    )
}
export default PowerUpPreview