interface CalloutProps {
    className?: string;
    children?: React.ReactNode;
}

const Callout = ({className, children}: CalloutProps) => {
    return (
        <div className={"rounded-lg p-1 bg-gradient-to-br from-main-gradient-first to-main-gradient-second " + className}>
            <div className={"flex bg-purple-100 py-5 px-4 rounded-md"}>
                {children}
            </div>
        </div>
    )
}

export default Callout