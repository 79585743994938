import {AnimatePresence, motion} from "framer-motion";
import MainButton from "../../components/buttons/MainButton";
import HeadingDescriptor from "../../components/text/HeadingDescriptor";
import Heading3 from "../../components/text/Heading3";
import React, {useState} from "react";
import {CheckBadgeIcon, CheckCircleIcon} from "@heroicons/react/24/outline";

interface FinalStepProps {
    onNextPressed: () => void
    stepVisible: boolean
}

const FinalStep = ({onNextPressed, stepVisible}:FinalStepProps) => {

    const [loading, setLoading] = useState(false);

    const nextPressed = () => {
        setLoading(true)
        onNextPressed();
    }

    return (
        <>
            <AnimatePresence>
                {stepVisible && (
                    <motion.div
                        initial={{ opacity: 0, rotateZ: 30, display: "none" }}
                        animate={stepVisible ? { opacity: 1, rotateZ: 0, display: "contents", transition: {delay: 0.6} } : {}} // Animate only when dataLoaded is true
                        exit={{ opacity: 0}}
                        transition={{ duration: 0.3 }}>

                        <CheckBadgeIcon className={"text-main-gradient-first size-20"} />

                    </motion.div>
                )}
            </AnimatePresence>

            <AnimatePresence>
                {stepVisible && (
                    <motion.div
                        initial={{ opacity: 0, height: 0 }}
                        animate={stepVisible ? { opacity: 1, height: "unset", transition: {delay: 1.3} } : {}} // Animate only when dataLoaded is true
                        exit={{ opacity: 0}}
                        transition={{ duration: 0.3 }}
                        className={"flex flex-col items-center"}>

                        <Heading3 className={"mt-2"}>Det var alt!</Heading3>
                        <HeadingDescriptor className={"mb-3"}>Velkommen til Enthemed</HeadingDescriptor>

                    </motion.div>
                )}
            </AnimatePresence>

            <AnimatePresence>
                {stepVisible && (
                    <motion.div
                        initial={{ opacity: 0, height: 0 }}
                        animate={stepVisible ? { opacity: 1, height: "unset", transition: {delay: 1.3} } : {}} // Animate only when dataLoaded is true
                        exit={{ opacity: 0}}
                        transition={{ duration: 0.3 }}
                        className={"flex flex-col items-center"}>

                        <MainButton onClickEvent={nextPressed} showProcessingAnimation={loading}>Sett i gang</MainButton>

                    </motion.div>
                )}
            </AnimatePresence>
        </>
    )
}

export default FinalStep