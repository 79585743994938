import React, {ReactNode, useEffect, useState} from 'react';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useFormContext, RegisterOptions } from "react-hook-form";
import ErrorText from "../input-fields/ErrorText";

interface CheckBoxProps {
    name: string;
    children?: ReactNode;
    onClickEvent?: () => void;
    value?: boolean | null; // Corrected to use the primitive boolean type
    isCheckedByDefault?: boolean; // Ensuring this is the primitive boolean type
    className?: string;
    validation?: RegisterOptions;
}


const CheckBoxComponent: React.FC<CheckBoxProps> = ({ name, children, onClickEvent, isCheckedByDefault, className, validation }) => {
    const { register, formState: { errors } } = useFormContext();

    // Custom registration to manually handle onChange and integrate validation
    const { ref, onChange, ...rest } = register(name, validation);

    const [checked, setChecked] = useState<boolean>(false)

    useEffect(() => {
        setChecked(isCheckedByDefault ?? false)
    }, [isCheckedByDefault]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        // Call the form's register onChange method for validation
        onChange(event);

        setChecked(!checked)

        // Then, call the additional onClickEvent if provided
        if (onClickEvent) {
            onClickEvent();
        }
    };

    return (
        <div className={`col-md-offset-2 col-md-10 text-xs ${className}`}>
            <FormControlLabel
                control={
                    <Checkbox
                        size="small"
                        onChange={handleChange}
                        checked={checked} // Default to false if isClicked is undefined
                        ref={ref} // Apply the ref from register for React Hook Form
                        {...rest} // Apply rest of the properties from register
                    />
                }
                label={<span style={{ fontSize: '0.75rem' }}>{children}</span>}
                style={{ fontSize: '0.75rem' }}
            />
            <ErrorText errors={errors} name={name} />
        </div>
    );
};

export default CheckBoxComponent;