import {FieldValues, FormProvider, useForm} from "react-hook-form";
import InputTextField from "../../components/input-fields/InputTextField";
import MainButton from "../../components/buttons/MainButton";
import DestructiveButton from "../../components/buttons/DestructiveButton";
import Callout from "../../components/text/Callout";
import axios from "axios";
import {getBackendURL} from "../../utils/EnvironmentsManager";
import toast from "react-hot-toast";
import {useEffect, useState} from "react";
import {usePowerUpsProvider} from "../../providers/PowerUpsProvider";

const InactiveTabForm = () => {
    const {closeCustomizeMenu, disablePowerUp} = usePowerUpsProvider()
    const methods = useForm()
    const [existingText, setExistingText] = useState<string | null | undefined>(undefined)

    const handleSubmit = (values:FieldValues) => {
        axios.post(getBackendURL() + "/api/v1/power-ups/inactive-tab-warning/settings", {
            "text": values["inactiveTabText"]
        }).then(result => {
            toast.success("Lagret!");
        }).catch(error => {
            toast.error("En feil har dessverre oppstått")
        }).finally(() => {
            closeCustomizeMenu()
        })
    }

    useEffect(() => {
        axios.get(getBackendURL() + "/api/v1/power-ups/inactive-tab-warning/settings").then(result => {
            setExistingText(result.data.text)
        }).catch((error) => {
            // if the user does not have any settings
            if (error.response.status !== 404) {
                toast.error("En feil har oppstått");
            }
            setExistingText(null)
        })
    }, []);

    return (
        <>
            <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(handleSubmit)}>
                    <div className={"flex flex-col max-w-96 mx-auto space-y-5 justify-center my-5"}>
                        <Callout>
                            Hvis en kunde bytter fane, vil følgende tekst vises i navnet på fanen.
                        </Callout>
                        {existingText !== undefined && (
                            <InputTextField
                                name={"inactiveTabText"}
                                type={"text"}
                                label={"Inaktiv fane tekst"}
                                value={existingText || "🔥 Ikke glem dine produkter!"}
                                validation={{
                                    required: {value: true, message: 'Oppgi en tekst'},
                                    minLength: {value: 1, message: 'Teksten kan ikke være tom'},
                                    maxLength: {value: 75, message: 'Teksten kan ikke være lenger enn 75 tegn'}
                                }}
                            />
                        )}
                    </div>
                    <div className={"flex justify-center space-x-4"}>
                        <DestructiveButton onClickEvent={disablePowerUp}>
                            Deaktiver
                        </DestructiveButton>
                        <MainButton>
                            Lagre
                        </MainButton>
                    </div>
                </form>
            </FormProvider>
        </>
    )
}

export default InactiveTabForm;