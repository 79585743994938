import React from 'react';
import Heading1 from "../../components/text/Heading1";
import HeadingDescriptor from "../../components/text/HeadingDescriptor";
import Heading2 from "../../components/text/Heading2";
import LinkText from "../../components/input-fields/LinkText";
import {getFrontendURL} from "../../utils/EnvironmentsManager";


/***************************************************************************
* THIS PAGE SHOULD NEVER BE DELETED AS IT IS A LEGAL REQUIREMENT TO HAVE
* AND FACEBOOK REQUIRES IT!
***************************************************************************/
const DeleteMeGuidePage = () => {
    return (
        <div className="bg-white p-12 shadow-lg rounded-md sm:mx-auto sm:max-w-4xl my-10">
            <Heading1>Hvordan slette kontoen min?</Heading1>
            <HeadingDescriptor>
                Denne guiden beskriver hvordan du kan slette din Enthemed konto og alle tilhørende data
            </HeadingDescriptor>
            <hr className="my-4"/>
            <Heading2>Viktig å vite</Heading2>
            <HeadingDescriptor leftAligned={true}>
                Når du sletter din Enthemed-konto, vil abonnementet ditt automatisk bli avsluttet. Vær oppmerksom på at
                kontoen din ikke kan gjenopprettes etter at den er slettet.
            </HeadingDescriptor>

            <Heading2>Slik sletter du kontoen din</Heading2>
            <HeadingDescriptor leftAligned={true}>
                For å slette din Enthemed-konto og alle tilknyttede data, følg disse trinnene:
                <ul className={"flex flex-col ml-6 mt-2 gap-y-3 list-decimal"}>
                    <li><LinkText href={"/"}>Logg inn</LinkText> på din Enthemed-konto.</li>
                    <li>Trykk på <LinkText href={"/settings"}>innstillinger</LinkText> (tannhjulet) nederst til venstre.</li>
                    <li>Scroll ned til bunnen og trykk på "Slett konto".</li>
                </ul>
            </HeadingDescriptor>

            <Heading2>Data som ikke slettes</Heading2>
            <HeadingDescriptor leftAligned={true}>
                Selv om kontoen din og alle personopplysninger slettes, er vi pålagt å beholde visse data av juridiske
                årsaker. Dette inkluderer informasjon knyttet til finansielle transaksjoner, som ditt
                Enthemed-abonnement.
            </HeadingDescriptor>

        </div>
    );
};

export default DeleteMeGuidePage;