import {createContext, ReactNode, useContext, useEffect, useState} from "react";
import axios from "axios";
import {getBackendURL} from "../utils/EnvironmentsManager";
import {toastError} from "../utils/ErrorHandlerUtils";
import {ThemeDTO} from "../types/ThemeDTO";

interface ThemesContextHolder {
    themes: ThemeDTO[];
}

const ThemesContext = createContext<ThemesContextHolder>({
    themes: []
});

export const useThemes = () => {
    return useContext(ThemesContext);
}

interface ThemesProviderProps {
    children: ReactNode;
}

export const ThemesProvider = ({children}: ThemesProviderProps) => {
    const [themes, setThemes] = useState<ThemeDTO[]>([]);

    const loadThemes = () => {
        axios.get(getBackendURL() + "/api/v1/theme/list").then(result => {
            setThemes(result.data);
        }).catch(error => {
            toastError(error)
        })
    };

    useEffect(() => {
        loadThemes();
    }, []);

    return (
        <ThemesContext.Provider value={{themes}}>
            {children}
        </ThemesContext.Provider>
    )
}