// UnifiedSettings.tsx
import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";
import Heading3 from "../../../components/text/Heading3";
import { ArrowLeftIcon } from "@heroicons/react/24/outline";
import { ChevronRightIcon } from "@heroicons/react/24/solid";
import SettingsFragment from "./SettingsFragment";

interface SettingsProps {
    pages: SettingsFragment[];
    isMobile: boolean;
}

const Settings= ({pages, isMobile}: SettingsProps) => {
    const [activeSettingsPage, setActiveSettingsPage] = useState<SettingsFragment | null>(null);

    useEffect(() => {
        const storedTabName = localStorage.getItem('activeSettingsTab');

        if (storedTabName) {
            const storedTab = pages.find(page => page.name === storedTabName);
            if (storedTab) {
                setActiveSettingsPage(storedTab);
            }
        } else if (!isMobile) {
            // Set first page as default only in desktop view
            setActiveSettingsPage(pages[0]);
        }
    }, [pages, isMobile]);

    const handleSettingsPageSelect = (fragment: SettingsFragment) => {
        setActiveSettingsPage(fragment);
        localStorage.setItem('activeSettingsTab', fragment.name);
    };

    const handleBack = () => {
        setActiveSettingsPage(null);
        localStorage.removeItem('activeSettingsTab');
    };

    if (isMobile) {
        const variants = {
            hidden: { opacity: 0, x: -200 },
            visible: { opacity: 1, x: 0 },
            exit: { opacity: 0, x: 200 },
        };

        return (
            <div className="min-h-screen p-4">
                <motion.div
                    className="bg-white shadow-lg rounded-lg max-w-md mx-auto"
                    initial="hidden"
                    animate="visible"
                    exit="exit"
                    variants={variants}
                    transition={{ duration: 0.3 }}
                >
                    {activeSettingsPage ? (
                        <motion.div
                            key={activeSettingsPage.name}
                            initial="hidden"
                            animate="visible"
                            exit="exit"
                            variants={variants}
                        >
                            <div className="flex items-center p-4 border-b">
                                <ArrowLeftIcon
                                    className="h-6 w-6 mr-2 cursor-pointer"
                                    onClick={handleBack}
                                />
                                <Heading3 className="ml-4">{activeSettingsPage.name}</Heading3>
                            </div>
                            <div className="p-4">
                                {activeSettingsPage.fragmentNode}
                            </div>
                        </motion.div>
                    ) : (
                        <motion.div
                            key="settings-list"
                            initial="hidden"
                            animate="visible"
                            exit="exit"
                            variants={variants}
                        >
                            <div className="divide-y divide-gray-200">
                                {pages.map((fragment) => (
                                    <div
                                        key={fragment.name}
                                        className="flex items-center justify-between p-4 hover:bg-gray-50 cursor-pointer"
                                        onClick={() => handleSettingsPageSelect(fragment)}
                                    >
                                        <div className="flex items-center">
                                            {fragment.icon}
                                            <span className="ml-4 text-lg">{fragment.name}</span>
                                        </div>
                                        <ChevronRightIcon className="h-6 w-6 text-gray-400" />
                                    </div>
                                ))}
                            </div>
                        </motion.div>
                    )}
                </motion.div>
            </div>
        );
    }

    return (
        <div className={"flex bg-white rounded-2xl shadow-lg mx-auto"} style={{minHeight: "900px"}}>
            <div className={"w-1/4 py-10 border-r-2 border-gray-100"}>
                <Heading3 className={"mb-3 px-8"}>Meny</Heading3>
                <List>
                    {pages.map((fragment) => (
                        <ListItem
                            key={fragment.name}
                            className={`hover:bg-gray-100 hover:cursor-pointer ${activeSettingsPage === fragment ? "bg-gray-200" : ""}`}
                            style={{paddingLeft: "2rem", paddingRight: "2rem", paddingTop: "1rem", paddingBottom: "1rem"}}
                            onClick={() => handleSettingsPageSelect(fragment)}
                        >
                            <div className="flex items-center">
                                {fragment.icon}
                                <span className="ml-4">{fragment.name}</span>
                            </div>
                        </ListItem>
                    ))}
                </List>
            </div>
            <div className={"w-3/4 p-10"}>
                {activeSettingsPage?.fragmentNode}
            </div>
        </div>
    );
};

export default Settings;