import React, {useState} from "react";
import {FieldValues, FormProvider, useForm} from "react-hook-form";
import Heading2 from "../../components/text/Heading2";
import InputTextField from "../../components/input-fields/InputTextField";
import MainButton from "../../components/buttons/MainButton";
import HeadingDescriptor from "../../components/text/HeadingDescriptor";
import LinkText from "../../components/input-fields/LinkText";
import ForgotPasswordSuccess from "../../components/authentication/ForgotPasswordSuccess";
import {useNavigate} from "react-router";
import axios from "axios";
import {getBackendURL} from "../../utils/EnvironmentsManager";
import {toastError} from "../../utils/ErrorHandlerUtils";

const ForgotPasswordPage = () => {
    const navigate = useNavigate()
    const methods = useForm()
    const [isSubmitted, setIsSubmitted] = useState(false)
    const [tryDifferentEmail, setTryDifferentEmail] = useState(false);

    const onSubmit = (values: FieldValues) => {
        axios.post(getBackendURL() + "/api/v1/user/forgot-password", {
            "email": values.email
        }).then((response) => {
            setIsSubmitted(true);
        }).catch((error) => {
            toastError(error)
        })
    };

    const resetEmailInput = () => {
        setIsSubmitted(false);
        setTryDifferentEmail(!tryDifferentEmail);  // Toggles the state to force a re-render
    };

    if (isSubmitted) {
        return <ForgotPasswordSuccess onTryDifferentEmail={resetEmailInput}/>;
    } else {
        return (
            <>
                <FormProvider {...methods} >
                    <div className="flex min-h-full flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8">
                        <div className="sm:mx-auto sm:w-full sm:max-w-md mb-8">
                            <img
                                className="mx-auto h-10 w-auto"
                                src="/enthemed-1.png"
                                alt="Enthemed"
                            />
                        </div>
                        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]">
                            <div className="bg-white px-6 py-12 shadow sm:rounded-lg sm:px-12">
                                <form className="space-y-4" onSubmit={methods.handleSubmit(onSubmit)}>
                                    <Heading2 className={"flex justify-center text-4xl"}>
                                        Glemt passord?
                                    </Heading2>
                                    <HeadingDescriptor>skriv inn e-postadressen som er knyttet til kontoen din, så sender vi
                                        deg en lenke for å tilbakestille passordet ditt.</HeadingDescriptor>
                                    <InputTextField
                                        name={"email"}
                                        type={"email"}
                                        label={"E-post"}
                                        style={{width: "100%"}}
                                        validation={{
                                            required: {value: true, message: 'Email is required'},
                                            maxLength: {value: 50, message: "The Email is too long"}
                                        }}/>
                                    <MainButton className={"w-full mb-5"}>Send lenke</MainButton>

                                    <p className={"text-center"}>
                                        <LinkText href={"/login"}>
                                            Tilbake til logg inn
                                        </LinkText>
                                    </p>
                                </form>
                            </div>
                        </div>
                    </div>
                </FormProvider>
            </>
        )
    }
};
export default ForgotPasswordPage;