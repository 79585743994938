import React, { ReactNode } from 'react';

interface Heading2Props {
    children: ReactNode;
    className?: string;
    withoutMargin?: boolean; // Add the withoutMargin property
}

const Heading2: React.FC<Heading2Props> = ({ children, className, withoutMargin }) => {
    return (
        <h2 className={
            "text-2xl font-semibold " +
            (withoutMargin ? "" : "mb-2 ") + // Apply margin conditionally
            className
        }>
            {children}
        </h2>
    );
}

export default Heading2;
