import * as React from 'react';
import {ReactNode, useEffect, useRef, useState} from "react";
import WelcomeStep from "../pages/onboardings/WelcomeStep";
import FinalStep from "../pages/onboardings/FinalStep";
import {useCurrentUser} from "../providers/UserProvider";
import InputStoreNameStep from "../pages/onboardings/InputStoreNameStep";
import {useSearchParams} from "react-router-dom";
import SubscriptionSucceedStep from "../pages/onboardings/SubscriptionSucceedStep";
import axios from "axios";
import {getBackendURL} from "../utils/EnvironmentsManager";
import {toastError} from "../utils/ErrorHandlerUtils";
import ConnectToShopifyStep from "../pages/onboardings/ConnectToShopifyStep";
import IntegrateWithShopifyStep from "../pages/onboardings/IntegrateWithShopifyStep";
import ConfigureAppStep from "../pages/onboardings/ConfigureAppStep";
import SetUpAppStep from "../pages/onboardings/SetUpAppStep";
import Spinner from "../components/loading/Spinner";
import {motion} from "framer-motion";
import {PopupComponent} from "../components/modals/PopupComponent";
import Heading1 from "../components/text/Heading1";
import HeadingDescriptor from "../components/text/HeadingDescriptor";
import MainButton from "../components/buttons/MainButton";
import SecondaryButton from "../components/buttons/SecondaryButton";
import {OnboardingStatus} from "../enums/OnboardingStatus";
import {Store} from "../types/Store";

interface OnboardingWrapperProps {
    children?: ReactNode;
}

const OnboardingWrapper = ({children}: OnboardingWrapperProps) => {
    const [isLicenseActive, setIsLicenseActive] = useState<boolean | undefined>(undefined);
    const [currentStep, setCurrentStep] = useState(-1);
    const {currentUser, refreshUser} = useCurrentUser()
    const [store, setStore] = useState<Store | undefined | null>(undefined)

    const [skipOnboardingPopupVisible, setSkipOnboardingPopupVisible] = useState<boolean>(false)
    const [isProcessing, setIsProcessing] = useState(false)

    const checkActiveLicense = () => {
        axios.get(getBackendURL() + "/api/v1/license/check-for-myself")
            .then(result => {
                if (result.data === true) {
                    setIsLicenseActive(true);
                } else if (result.data === false) {
                    setIsLicenseActive(false);
                }
            })
            .catch(error => {
                console.log(error);
            });
    }

    useEffect(() => {
        const interval = setInterval(() => {
            checkActiveLicense();
        }, 1000);

        if (isLicenseActive === undefined) {
            checkActiveLicense()
        }

        if (isLicenseActive) {
            clearInterval(interval);
        }

        return () => clearInterval(interval); // Clean up on component unmount
    }, [isLicenseActive, currentUser]);

    useEffect(() => {
        if (isLicenseActive === undefined) {
            return
        }
        axios.get(getBackendURL() + "/api/v1/store").then(result => {
            let store = result.data as Store | undefined
            if (!store) {
                setCurrentStep(0)
                return
            }

            setStore(store)
            setCurrentStep(2) // fyll inn Shopify access token
        }).catch(error => {
            setStore(null)
            setCurrentStep(0)
        })
    }, [isLicenseActive, currentUser]);

    const nextPageClicked = () => {
        setCurrentStep(currentStep +1)
    };

    const backPageClicked = () => {
        setCurrentStep(currentStep -1)
    };

    const finalStepPressed = () => {
        axios.post(getBackendURL() + "/api/v1/user/complete-onboarding").then(result => {
            refreshUser();
        }).catch(error => {
            toastError(error);
        });
    };

    const skipOnboardingClicked = () => {
        setSkipOnboardingPopupVisible(true)
    };

    const confirmSkipOnboarding = () => {
        setIsProcessing(true)
        axios.post(getBackendURL() + "/api/v1/user/skip-onboarding").then(result => {
            setSkipOnboardingPopupVisible(false)
            refreshUser();
        }).catch(error => {
            toastError(error);
        }).finally(() => {
            setIsProcessing(false)
        });
    }

    return (
        <>
            <PopupComponent
                isVisible={skipOnboardingPopupVisible}
                closePopup={() => setSkipOnboardingPopupVisible(false)}
            >
                <Heading1>Fullfør senere?</Heading1>
                <HeadingDescriptor>
                    Du kan ikke bruke Enthemed med nettbutikken din før du har fullført
                    onboardingen.
                </HeadingDescriptor>
                <div className={"flex justify-center items-center gap-x-4"}>
                    <SecondaryButton
                        showProcessingAnimation={isProcessing}
                        onClickEvent={() => confirmSkipOnboarding()}
                    >
                        Fullfør senere
                    </SecondaryButton>
                    <MainButton
                        disabled={isProcessing}
                        onClickEvent={() => setSkipOnboardingPopupVisible(false)}
                    >
                        Fullfør nå
                    </MainButton>
                </div>
            </PopupComponent>
            {/* License not loaded yet */}
            {isLicenseActive === undefined && (
                <div className="w-screen h-screen flex justify-center items-center">
                    <Spinner/>
                </div>
            )}

            {/* License loaded */}
            {isLicenseActive && (
                <>
                    {currentUser && currentUser.onboardingStatus == OnboardingStatus.INCOMPLETE ? (
                        <>
                            <div className={"flex flex-col items-center justify-center h-screen mx-5 "}>
                                {currentStep === -1 && (
                                    <>
                                        <Spinner />
                                    </>
                                )}
                                <SubscriptionSucceedStep
                                    stepVisible={currentStep === 0}
                                    onNextPressed={nextPageClicked}
                                />
                                <InputStoreNameStep
                                    stepVisible={currentStep === 1}
                                    onNextPressed={nextPageClicked}
                                />
                                <IntegrateWithShopifyStep
                                    stepVisible={currentStep === 2}
                                    onNextPressed={nextPageClicked}
                                />
                                <ConfigureAppStep
                                    stepVisible={currentStep === 3}
                                    onNextPressed={nextPageClicked}
                                    onBackPressed={backPageClicked}
                                />
                                <SetUpAppStep
                                    stepVisible={currentStep === 4}
                                    onNextPressed={nextPageClicked}
                                    onBackPressed={backPageClicked}
                                />
                                <ConnectToShopifyStep
                                    stepVisible={currentStep === 5}
                                    onNextPressed={nextPageClicked}
                                    onBackPressed={backPageClicked}
                                />
                                <FinalStep
                                    stepVisible={currentStep === 6}
                                    onNextPressed={finalStepPressed}
                                />
                                {currentStep !== 6 && (
                                    <motion.p
                                        initial={{opacity: 0}}
                                        animate={{opacity: 1}}
                                        transition={{delay: 1.3}}
                                        onClick={() => skipOnboardingClicked()}
                                        className={"text-sm text-gray-500 mt-4 cursor-pointer hover:text-main-color hover:scale-105 transition-all"}
                                    >
                                        Gjør onboardingen senere
                                    </motion.p>
                                )}
                            </div>
                        </>
                    ) : (
                        <>
                            {children}
                        </>
                    )}
                </>
            )}

            {/* No license */}
            {!isLicenseActive && (
                <div className={"flex flex-col items-center justify-center h-screen mx-5 "}>
                    <WelcomeStep stepVisible={true}/>
                </div>
            )}
        </>
    );
};

export default OnboardingWrapper;