import {createContext, useContext, useEffect, useState} from "react";
import axios from "axios";
import {getBackendURL} from "../utils/EnvironmentsManager";
import LicenseInvalidPage from "../pages/license-invalid/LicenseInvalidPage";

interface LicenseHolder {
    validLicense?: boolean
    checkLicense: () => void
}

export const LicenseContext = createContext<LicenseHolder>({
    checkLicense: () => {},
    validLicense: true
})

export const useLicenseProvider = () => {
    const context = useContext(LicenseContext);
    if (!context) {
        throw new Error("useLicenseProvider must be used within a RequiresLicense area");
    }
    return context;
}

export interface RequiresLicenseProps {
    children: React.ReactNode
}

export const RequiresLicense = ({children}: RequiresLicenseProps) => {
    const [validLicense, setValidLicense] = useState(undefined)

    useEffect(() => {
        checkLicense()
    }, []);

    const checkLicense = () => {
        axios.get(getBackendURL() + "/api/v1/license/check-for-myself").then(result => {
            setValidLicense(result.data)
        })
    }

    return (
        <LicenseContext.Provider value={{validLicense, checkLicense}}>
            {validLicense === undefined && (
                <>

                </>
            )}

            {validLicense === false && (
                <LicenseInvalidPage />
            )}

            {validLicense === true && (
                <>
                    {children}
                </>
            )}

        </LicenseContext.Provider>
    )
}