import {PopupComponent} from "../../../components/modals/PopupComponent";
import {PowerUpsCustomizeInfo} from "../types/PowerUpsCustomizeInfo";
import Heading1 from "../../../components/text/Heading1";

export interface PowerUpsCustomizePopupProps {
    currentlyCustomizing: PowerUpsCustomizeInfo | undefined
    closePopup: () => void
}

const PowerUpsCustomizePopup = ({currentlyCustomizing, closePopup}: PowerUpsCustomizePopupProps) => {
    return (
        <>
            <PopupComponent closePopup={closePopup} isVisible={!!currentlyCustomizing}>
                <Heading1>{currentlyCustomizing?.powerUpDTO.title}</Heading1>
                <hr className={"mt-5"}/>
                {currentlyCustomizing?.fields}
            </PopupComponent>
        </>
    )
}

export default PowerUpsCustomizePopup