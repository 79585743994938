import {AnimatePresence, motion} from "framer-motion";
import React, {useEffect} from "react";
import {XMarkIcon} from "@heroicons/react/24/outline";
import ReactDOM from "react-dom";

export interface PopupComponentProps {
    closePopup: () => void;
    isVisible: boolean;
    children?: React.ReactNode;
}

export const PopupComponent = ({closePopup, isVisible, children}: PopupComponentProps) => {

    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.key === 'Escape') {
                closePopup();
            }
        };

        if (isVisible) {
            window.addEventListener('keydown', handleKeyDown);
        } else {
            window.removeEventListener('keydown', handleKeyDown);
        }

        // Cleanup event listener on component unmount
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [isVisible]);

    const modalContent = isVisible ? (
        <AnimatePresence>
            {isVisible ?
                <div className={"fixed inset-0 flex items-center justify-center z-50"}>
                    <motion.div
                        initial={{opacity: 0}}
                        animate={{opacity: 0.5}}
                        exit={{opacity: 0}}
                        transition={{
                            enter: {ease: "easeOut", duration: 0.2},
                            leave: {ease: "easeIn", duration: 0.2},
                            duration: 0.4
                        }}
                        className={"fixed inset-0 bg-black"}
                        onClick={closePopup}
                    />

                    <motion.div
                        initial={{scale: 0.8, opacity: 0}}
                        animate={{scale: 1, opacity: 1}}
                        exit={{scale: 0.8, opacity: 0}}
                        transition={{
                            enter: {ease: "easeOut", duration: 0.2},
                            leave: {ease: "easeIn", duration: 0.2},
                        }}
                        className={"relative bg-white w-full h-screen p-10 shadow-xl z-50 sm:h-fit sm:w-[700px] sm:rounded-lg"}
                        onClick={(e) => e.stopPropagation()}
                    >
                        <button
                            type="button"
                            onClick={closePopup}
                            className="absolute top-0 right-0 flex w-16 justify-center pt-5">
                            <span className="sr-only">Close popup</span>
                            <XMarkIcon className="h-6 w-6 text-black" aria-hidden="true"/>
                        </button>
                        {children}
                    </motion.div>
                </div>
                : ""}
        </AnimatePresence>
    ) : null;
    return ReactDOM.createPortal(modalContent, document.body);
}