import React from "react";
import {ProductConfig} from "../../../types/ProductConfig";
import InputDateField from "../../../components/input-fields/InputDateField";
import {PreOrderRule} from "../types/PreOrderRule";


interface PreOrderProductSettingsProps {
    productConfig: ProductConfig
    checkProduct: (product: any) => void;
    allRules: PreOrderRule[]
}

export const PreOrderProductSettings = ({ productConfig, checkProduct, allRules}: PreOrderProductSettingsProps) => {

    return (
        <div
            key={productConfig.product.shopifyAdminAPIId}
            className="py-4 border-b-2 border-gray-100">
            <input
                type="checkbox"
                checked={productConfig.checked}
                onChange={() => checkProduct(productConfig)}
                className={""}
            />
            <span className={"ml-4 font-semibold text-lg"}>{productConfig.product.title}</span>
            {productConfig.checked && (
                <div className="mx-10">
                    {productConfig.product.variants.map(variant => (
                        <div className={"flex flex-row items-center my-4"}>
                            <span>{variant.title !== "Default Title" ? variant.title : productConfig.product.title}</span>
                            <InputDateField
                                key={variant.shopifyAdminAPIId}
                                name={`${variant.shopifyAdminAPIId}_endDate`}
                                label="Estimert leveringsdato"
                                style={{marginLeft: "auto"}}
                                value={allRules.find(rule => rule.variantId == variant.shopifyAdminAPIId)?.expectedDelivery}
                            />
                        </div>
                    ))}

                </div>
            )}
        </div>
    );
};
