import { AnimatePresence, motion } from "framer-motion";
import MainButton from "../../components/buttons/MainButton";
import React, { useState } from "react";
import { Card } from "@mui/material";
import Heading1 from "../../components/text/Heading1";

interface IntegrateWithShopifyStepProps {
    onNextPressed: () => void;
    stepVisible: boolean;
}

const IntegrateWithShopifyStep = ({ onNextPressed, stepVisible }: IntegrateWithShopifyStepProps) => {
    const [loading, setLoading] = useState(false);

    return (
        <>
            <AnimatePresence>
                {stepVisible && (
                    <motion.div
                        initial={{ opacity: 0, scaleY: 0 }}
                        animate={stepVisible ? { opacity: 1, scaleY: 1, transition: { delay: 0.6, duration: 0.2 } } : {}}
                        exit={{ opacity: 0, scaleY: 0 }}
                        transition={{ duration: 0.1 }}
                        className={"flex items-center justify-center"}
                    >
                        <Card className={"px-10 py-10 rounded-xl flex flex-col items-center"}>
                            <div className="flex flex-col items-center text-center">
                                <img src="/integrermedshopify.png" alt="Shopify Integration" className="w-auto"
                                     style={{ maxWidth: '350px', maxHeight: '200px' }} />
                                <Heading1>Integrer med Shopify</Heading1>
                                <p className="mb-4">Du er snart i mål... Fullfør integrasjon på neste steg for å komme i gang</p>
                                <MainButton onClickEvent={onNextPressed} showProcessingAnimation={loading}>
                                    Koble til nå
                                </MainButton>
                            </div>
                        </Card>
                    </motion.div>
                )}
            </AnimatePresence>
        </>
    );
};

export default IntegrateWithShopifyStep;
