import React, { ReactNode } from 'react';

interface Heading1Props {
    children: ReactNode;
    className?: string;
    leftAligned?: boolean;
    withoutMargin?: boolean; // Add the withoutMargin property
}

const Heading1: React.FC<Heading1Props> = ({ children, className, leftAligned, withoutMargin }) => {
    return (
        <h1 className={
            "text-5xl font-bold " +
            (withoutMargin ? "" : "mb-4 ") + // Apply margin conditionally
            (leftAligned ? "text-left " : "text-center ") +
            className
        }>
            {children}
        </h1>
    );
}

export default Heading1;
