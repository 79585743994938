import React, { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import axios from "axios";
import { getBackendURL } from "../../../utils/EnvironmentsManager";
import { toastError } from "../../../utils/ErrorHandlerUtils";
import Heading2 from "../../../components/text/Heading2";
import HeadingDescriptor from "../../../components/text/HeadingDescriptor";
import { useCurrentUser } from "../../../providers/UserProvider";
import { useStoreProvider } from "../../../providers/StoreProvider";
import SettingsFragment from "./SettingsFragment";
import { PopupComponent } from "../../../components/modals/PopupComponent";
import Heading1 from "../../../components/text/Heading1";
import MainButton from "../../../components/buttons/MainButton";
import SecondaryButton from "../../../components/buttons/SecondaryButton";
import InputTextField from "../../../components/input-fields/InputTextField";
import toast from "react-hot-toast";
import {CheckCircleIcon, PuzzlePieceIcon} from "@heroicons/react/24/outline";
import { Callout } from "../../../components/callout/Callout";
import {OnboardingStatus} from "../../../enums/OnboardingStatus";
import { Store } from "../../../types/Store";

interface FormInputs {
    'access-token': string;
    'api-key': string;
    'secret-key': string;
}

const CustomAppFragmentNode: React.FC = () => {
    const methods = useForm<FormInputs>({
        mode: "onChange"
    });
    const [loading, setLoading] = useState<boolean>(false);
    const { currentUser, refreshUser } = useCurrentUser();
    const { store, showIntegrateStorePrompt } = useStoreProvider();
    const [isPopupVisible, setIsPopupVisible] = useState<boolean>(false);
    const [showForm, setShowForm] = useState<boolean>(false);

    const updateAPICredentials = async (): Promise<void> => {
        setLoading(true);
        try {
            await axios.post(getBackendURL() + "/api/v1/store/connect-to-shopify", {
                accessToken: methods.getValues("access-token"),
                apiKey: methods.getValues("api-key"),
                secretKey: methods.getValues("secret-key")
            });
            toast.success("API-nøklene ble oppdatert");
            setShowForm(false);
            setIsPopupVisible(false);
        } catch (error: any) {
            if (error.response?.status === 403) {
                toast.error(error.response.data);
                return;
            }
            toastError(error);
        } finally {
            setLoading(false);
        }
    };

    const finishOnboardingPressed = async (): Promise<void> => {
        try {
            await axios.post(getBackendURL() + "/api/v1/user/unskip-onboarding");
            refreshUser && refreshUser();
        } catch (error) {
            toast.error("En feil har dessverre oppstått");
        }
    };

    // Early return if onboarding is skipped
    if (currentUser?.onboardingStatus === OnboardingStatus.SKIPPED) {
        return (
            <div className="space-y-6">
                <Heading2>API-innstillinger</Heading2>
                <Callout
                    message="Her kan du administrere custom appen din, men du må fullføre onboardingen først."
                    variant="info"
                    expandedStyle={true}
                    actionLabel={""}
                    mainButtonLabel="Fullfør onboarding nå"
                    mainButtonOnClick={finishOnboardingPressed}
                />
            </div>
        );
    }

    if (!store?.credentialsForStoreRegistered) {
        return (
            <div className="space-y-6">
                <div>
                    <Heading2>API-innstillinger</Heading2>
                </div>

                <div className="space-y-6">
                    <Callout
                        message="Det ser ut som custom appen din har blitt slettet eller ikke er tilgjengelig lenger. For å fortsette å bruke tjenesten må du opprette en ny tilkobling til Shopify."
                        variant="warning"
                        expandedStyle={true}
                        actionLabel=""
                    />

                    <div className="flex justify-center">
                        <MainButton
                            onClickEvent={() => {
                                showIntegrateStorePrompt!(
                                    "Koble til Shopify på nytt",
                                    <>
                                        <span>For å koble til Shopify på nytt må du installere appen på nytt. Vi vil guide deg gjennom prosessen.</span>
                                    </>
                                );
                            }}
                        >
                            Koble til Shopify
                        </MainButton>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="space-y-6">
            <div>
                <Heading2>API-innstillinger</Heading2>
            </div>

            <div className="space-y-6 text-center">
                <img src="/integrermedshopify.png" alt="Shopify Integration"
                     className="w-auto mx-auto"
                     style={{maxWidth: '350px', maxHeight: '200px'}}/>

                <div className="flex flex-col items-center justify-center">
                    <HeadingDescriptor withoutMargin={true}>
                        Integrasjonen med Shopify er opprettet
                    </HeadingDescriptor>
                    <CheckCircleIcon className="text-green-500 w-8 h-8"/>
                </div>

                <p className="text-gray-500 cursor-pointer mt-4 underline"
                   onClick={() => setShowForm(true)}>
                    Endre custom app?
                </p>

                {showForm && (
                    <FormProvider {...methods}>
                        <Callout
                            message="Du trenger som regel ikke å oppdattere custom app, med mindre den ikke funker eller har blitt slettet. Hvis du ønsker å oppdatere API-nøklene, må alle tre nøklene oppdateres samtidig. "
                            variant="info"
                            expandedStyle={true}
                            actionLabel=""
                        />
                        <form className="space-y-6 mt-6" onSubmit={methods.handleSubmit(() => {
                            setIsPopupVisible(true);
                        })}>
                            <div className="space-y-4">
                                <InputTextField
                                    name="access-token"
                                    type="text"
                                    label="Admin API Access Token"
                                    validation={{
                                        required: "Admin API Access Token er påkrevd",
                                        maxLength: {
                                            value: 70,
                                            message: "Admin API Access Token kan ikke være lengre enn 70 tegn"
                                        },
                                        minLength: {
                                            value: 10,
                                            message: "Admin API Access Token må være minst 10 tegn"
                                        }
                                    }}
                                    style={{width: "100%"}}
                                />

                                <InputTextField
                                    name="api-key"
                                    type="text"
                                    label="API key"
                                    validation={{
                                        required: "API key er påkrevd",
                                        maxLength: {
                                            value: 70,
                                            message: "API key kan ikke være lengre enn 70 tegn"
                                        },
                                        minLength: {
                                            value: 10,
                                            message: "API key må være minst 10 tegn"
                                        }
                                    }}
                                    style={{width: "100%"}}
                                />

                                <InputTextField
                                    name="secret-key"
                                    type="text"
                                    label="API secret key"
                                    validation={{
                                        required: "API secret key er påkrevd",
                                        maxLength: {
                                            value: 70,
                                            message: "API secret key kan ikke være lengre enn 70 tegn"
                                        },
                                        minLength: {
                                            value: 10,
                                            message: "API secret key må være minst 10 tegn"
                                        }
                                    }}
                                    style={{width: "100%"}}
                                />
                            </div>

                            <MainButton
                                disabled={loading || !methods.formState.isValid}
                                className="w-full sm:w-auto"
                            >
                                {loading ? "Oppdaterer..." : "Oppdater API-nøkler"}
                            </MainButton>
                        </form>
                    </FormProvider>
                )}
            </div>

            <PopupComponent
                isVisible={isPopupVisible}
                closePopup={() => setIsPopupVisible(false)}
            >
                <div className="space-y-4">
                    <Heading1>Bekreft oppdatering av API-nøkler</Heading1>
                    <div className="text-gray-600 space-y-2 text-ceb">
                        <p>
                            Oppdater API-nøkler kun hvis den tilpassede appen ble slettet ved en feil eller sluttet å fungere.
                        </p>
                        <p>
                            Sørg for at de nye nøklene:
                        </p>
                        <ul className="list-disc list-inside">
                            <li>Er kopiert korrekt fra Shopify</li>
                            <li>Har alle nødvendige tillatelser aktivert</li>
                            <li>Tilhører riktig nettbutikk i Shopify</li>
                        </ul>
                    </div>
                    <div className="flex justify-end gap-x-4 mt-6">
                        <SecondaryButton
                            onClickEvent={() => setIsPopupVisible(false)}
                        >
                            Avbryt
                        </SecondaryButton>
                        <MainButton
                            onClickEvent={updateAPICredentials}
                            disabled={loading}
                        >
                            {loading ? "Oppdaterer..." : "Bekreft"}
                        </MainButton>
                    </div>
                </div>
            </PopupComponent>
        </div>
    );
};

export const CustomAppFragment: SettingsFragment = {
    name: "Administrer custom app",
    icon: <PuzzlePieceIcon className="h-6 w-6 shrink-0 text-gray-400" />,
    fragmentNode: <CustomAppFragmentNode />,
};