import {createContext, useContext, useEffect, useState} from "react";
import axios from "axios";
import {getBackendURL} from "../utils/EnvironmentsManager";
import {PowerUpDTO} from "../types/PowerUpDTO";
import {PowerUpsCustomizeInfo} from "../pages/power-ups/types/PowerUpsCustomizeInfo";
import {getPowerUpCustomizeInfoForPowerUp} from "../pages/power-ups/PowerUpMapper";
import {toast} from "react-hot-toast";
import PowerUpsCustomizePopup from "../pages/power-ups/components/PowerUpsCustomizePopup";
import {useStoreProvider} from "./StoreProvider";

interface PowerUpsProviderHolder {
    powerUps: PowerUpDTO[] | undefined
    currentlyCustomizing: PowerUpsCustomizeInfo | undefined;
    customizePowerUp: (powerUpDTO: PowerUpDTO) => void;
    closeCustomizeMenu: () => void;
    disablePowerUp: (powerUpDTO?: PowerUpDTO) => Promise<void>;
    enablePowerUp: (powerUpDTO?: PowerUpDTO) => Promise<void>;
    setIsSendingData: (isSendingData: boolean) => void;
    isSendingData: boolean
}

export const PowerUpsContext = createContext<PowerUpsProviderHolder>({
    powerUps: undefined,
    currentlyCustomizing: undefined,
    customizePowerUp: () => {},
    closeCustomizeMenu: () => {},
    disablePowerUp: async () => {},
    enablePowerUp: async () => {},
    setIsSendingData: () => {},
    isSendingData: false
})

export const usePowerUpsProvider = () => {
    const context = useContext(PowerUpsContext);
    if (!context) {
        throw new Error("usePowerUpCustomizeProvider must be used within a PowerUpCustomizeProvider area");
    }
    return context;
}

export interface PowerUpsProviderProps {
    children: React.ReactNode;
    onPowerUpsChange?: (powerUpDTO: PowerUpDTO[]) => void;
}

export const PowerUpsProvider = ({children, onPowerUpsChange}: PowerUpsProviderProps) => {
    const {store} = useStoreProvider()
    const [currentlyCustomizing, setCurrentlyCustomizing] = useState<PowerUpsCustomizeInfo | undefined>(undefined)
    const [isSendingData, setIsSendingData] = useState<boolean>(false)
    const [powerUps, setPowerUps] = useState<PowerUpDTO[] | undefined>();

    useEffect(() => {
        getPowerUps()
    }, [store]);

    const getPowerUps = () => {
        setIsSendingData(true)
        axios.get(getBackendURL() + '/api/v1/power-ups/list').then(result => {
            setPowerUps(result.data);
            onPowerUpsChange && onPowerUpsChange(result.data)
        }).finally(() => {
            setIsSendingData(false)
        })
    }

    const customizePowerUp = (powerUpDTO: PowerUpDTO) => {
        let powerUpCustomizeInfo = getPowerUpCustomizeInfoForPowerUp(powerUpDTO)
        if (powerUpCustomizeInfo) {
            setCurrentlyCustomizing(powerUpCustomizeInfo)
        }
    }

    const closeCustomizeMenu = () => {
        setCurrentlyCustomizing(undefined)
    }

    const disablePowerUp = async (powerUpDTO?: PowerUpDTO) => {
        if (isSendingData) {
            return;
        }
        setIsSendingData(true)

        let p = powerUpDTO || currentlyCustomizing?.powerUpDTO
        let promise = axios.post(getBackendURL() + "/api/v1/power-ups/disable/" + p?.id).then(result => {
            closeCustomizeMenu()
            getPowerUps()
        }).finally(() => {
            setIsSendingData(false)
        })

        toast.promise(promise, {
            loading: "Skrur av " + p?.title,
            success: p?.title + " deaktivert!",
            error: "En ukjent feil har oppstått"
        })

        await promise
    }

    const enablePowerUp = async (powerUpDTO?: PowerUpDTO) => {
        if (isSendingData) {
            return;
        }

        setIsSendingData(true)

        let p = powerUpDTO || currentlyCustomizing?.powerUpDTO
        let promise = axios.post(getBackendURL() + "/api/v1/power-ups/enable/" + p?.id).then(result => {
            getPowerUps()
        }).finally(() => {
            setIsSendingData(false)
        });

        await toast.promise(promise, {
            loading: "Skrur på " + p?.title,
            success: p?.title + " påslått!",
            error: "En ukjent feil har oppstått"
        }).catch(error => {

        })
    }

    return (
        <PowerUpsContext.Provider value={{powerUps, currentlyCustomizing, customizePowerUp, closeCustomizeMenu, disablePowerUp, setIsSendingData, isSendingData, enablePowerUp}}>
            <PowerUpsCustomizePopup currentlyCustomizing={currentlyCustomizing} closePopup={closeCustomizeMenu}/>
            {children}
        </PowerUpsContext.Provider>
    )
}