import * as React from "react";
import {createContext, useContext, useEffect, useState} from "react";
import {Store} from "../types/Store";
import {motion} from "framer-motion";
import ConnectToShopifyStep from "../pages/onboardings/ConnectToShopifyStep";
import IntegrateWithShopifyStep from "../pages/onboardings/IntegrateWithShopifyStep";
import ConfigureAppStep from "../pages/onboardings/ConfigureAppStep";
import axios from "axios";
import {getBackendURL} from "../utils/EnvironmentsManager";
import {LicenseStatus} from "../enums/LicenseStatus";
import {toastError} from "../utils/ErrorHandlerUtils";
import Spinner from "../components/loading/Spinner";
import DropdownMenu, {MenuOption} from "../components/input-fields/DropdownMenu";
import {FormProvider, useForm} from "react-hook-form";
import toast from "react-hot-toast";
import {useNavigate} from "react-router";
import SetUpAppStep from "../pages/onboardings/SetUpAppStep";
import FinalStep from "../pages/onboardings/FinalStep";
import MainButton from "../components/buttons/MainButton";
import RegisterOrIntegrateStorePopup from "../components/modals/RegisterOrIntegrateStorePopup";

interface StoreHolder {
    store?: Store | null
    stores?: Store[]
    switchStore?: (store: Store) => void
    reloadStores?: () => void
    showRegisterStorePrompt?: (title: string, children: React.ReactNode) => void
    showIntegrateStorePrompt?: (title: string, children: React.ReactNode) => void
    registerStorePromptVisible: boolean
}

export const StoreContext = createContext<StoreHolder>({
    registerStorePromptVisible: false
})

export const useStoreProvider = () => {
    const context = useContext(StoreContext);
    if (!context) {
        throw new Error("useStoreProvider must be used within a StoreHolder area");
    }
    return context;
}

export interface StoreProviderProps {
    children: React.ReactNode
    onStoreChange: (store: Store | null) => void
}

export const StoreProvider = ({children, onStoreChange}: StoreProviderProps) => {
    const [store, setStore] = useState<Store | null | undefined>(undefined)
    const [creatingPortalLink, setCreatingPortalLink] = useState<boolean>(false)
    const [stores, setStores] = useState<Store[] | undefined>([])
    const [storesMenuOptions, setStoresMenuOptions] = useState<MenuOption[]>([])

    const [registerOrIntegrateStorePromp, setRegisterOrIntegrateStorePromp] = useState<{title: string, children: React.ReactNode, path: string, buttonText: string} | undefined>(undefined);

    const [registerOrIntegrateStorePromptVisible, setRegisterOrIntegrateStorePromptVisible] = useState<boolean>(false)

    const navigate = useNavigate()

    const methods = useForm();

    useEffect(() => {
        reloadStores()
    }, []);

    useEffect(() => {
        setRegisterOrIntegrateStorePromptVisible(registerOrIntegrateStorePromp != undefined)
    }, [registerOrIntegrateStorePromp]);

    const reloadStores = () => {
        loadStore()
        getStores()
    }

    const loadStore = () => {
        axios.get(getBackendURL() + "/api/v1/store").then(result => {
            let store = result.data as Store;
            if (!store) {
                setStore(null)
                onStoreChange(null)
                return
            }

            setStore(store)
            onStoreChange(store)
        })
    }

    const getStores = () => {
        axios.get(getBackendURL() + "/api/v1/store/list").then(result => {
            let stores = result.data as Store[];
            setStores(stores)
            setStoresMenuOptions(stores.map(store => {
                return {title: store.incomingHostname, id: store.id}
            }))
        })
    }

    const createCustomerPortalLinkAndRedirect = () => {
        setCreatingPortalLink(true)
        axios.post(getBackendURL() + "/api/v1/user/create-customer-portal-link").then(result => {
            window.location.href = result.data
        }).catch(error => {
            toastError(error)
            setCreatingPortalLink(false)
        })
    }

    const addStorePressed = () => {
        navigate("/add-store")
    }

    const switchStore = (store: Store) => {
        axios.put(getBackendURL() + "/api/v1/store/switch/" + store.id).then(result => {
            reloadStores()
        })
    }

    const showRegisterStorePrompt = (title: string, children: React.ReactNode) => {
        setRegisterOrIntegrateStorePromp({title: title, children: children, buttonText: "Registrer nettbutikk", path: "/add-store"})
    };

    const showIntegrateStorePrompt = (title: string, children: React.ReactNode) => {
        setRegisterOrIntegrateStorePromp({title: title, children: children, buttonText: "Integrer nettbutikk", path: "/integrate-store"})
    };

    return (
        <StoreContext.Provider value={{store, stores, switchStore, reloadStores, showRegisterStorePrompt, showIntegrateStorePrompt, registerStorePromptVisible: registerOrIntegrateStorePromptVisible}}>
            {stores && store !== undefined && (
                <FormProvider {...methods}>
                    <form className={" hidden md:flex ml-auto mr-10 mt-4 w-52"}>
                        <DropdownMenu
                            name={"store"}
                            label={"Butikk"}
                            options={storesMenuOptions}
                            defaultValue={store?.id}
                            onChange={(menuOption) => switchStore(stores?.filter(b => b.id == menuOption?.id)[0])}
                            actionButtonText={"Legg til nettbutikk"}
                            onActionButtonClicked={addStorePressed}
                            hideEmpty={true}
                            icon={"/shopify.svg"}
                        />
                    </form>
                </FormProvider>
            )}

            {registerOrIntegrateStorePromp != undefined && (
                <RegisterOrIntegrateStorePopup
                    title={registerOrIntegrateStorePromp?.title}
                    isVisible={true}
                    path={registerOrIntegrateStorePromp?.path}
                    handleCancelClick={() => setRegisterOrIntegrateStorePromp(undefined)}
                    buttonText={registerOrIntegrateStorePromp?.buttonText}
                >
                    {registerOrIntegrateStorePromp.children}
                </RegisterOrIntegrateStorePopup>
            )}

            {store && store?.license.status == LicenseStatus.OVERDUE && (
                <div
                    className={"mx-auto w-[95%] mt-5 text-center py-2 px-4 rounded-xl font-bold text-lg text-white md:mb-10 md:w-[500px] lg:w-[600px] " + (creatingPortalLink || "bg-red-500")}>
                    {creatingPortalLink ? (
                        <Spinner className={"flex justify-center"}/>
                    ) : (
                        <>
                            Noe gikk galt ved forrige betaling av ditt abonnement. Vi kommer til å forsøke igjen
                            i løpet av de neste dagene. Sørg for at betalingsinformasjonen er oppdatert <span
                            className={"text-purple-200 underline cursor-pointer"}
                            onClick={createCustomerPortalLinkAndRedirect}>her</span>
                        </>
                    )}
                </div>
            )}

            {children}


        </StoreContext.Provider>
    )
}