import Heading1 from "../text/Heading1";
import MainButton from "../buttons/MainButton";
import {PopupComponent} from "./PopupComponent";
import React from "react";
import {useNavigate} from "react-router";

interface RegisterOrIntegrateStorePopupProps {
    title: string;
    isVisible: boolean;
    path: string
    handleCancelClick: () => void;
    buttonText: string
    children: React.ReactNode;
}

const RegisterOrIntegrateStorePopup = ({title, isVisible, path, handleCancelClick, buttonText, children}: RegisterOrIntegrateStorePopupProps) => {
    const navigate = useNavigate();

    const registerStorePressed = () => {
        navigate(path + "?redirectUri=" + window.location.pathname)
        handleCancelClick && handleCancelClick()
    }

    return (
        <PopupComponent closePopup={handleCancelClick} isVisible={isVisible}>
            <div className="text-center space-y-6">
                <Heading1>{title}</Heading1>
                <p className={"text-lg"}>{children}</p>
                <div className={"flex flex-col items-center"}>
                    <MainButton
                        className={"mb-4"}
                        onClickEvent={registerStorePressed}
                    >
                        {buttonText}
                    </MainButton>
                </div>

            </div>
        </PopupComponent>
    )
}

export default RegisterOrIntegrateStorePopup;