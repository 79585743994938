import ListItem from "@mui/material/ListItem";
import React, {Dispatch, ReactElement, ReactNode, SetStateAction} from "react";
import {NavLink} from "react-router-dom";

export interface SidebarItem {
    text: string;
    icon: ReactElement<any, any>;
    link: string
    selected: () => boolean
}

interface SidebarListItemComponentProps {
    sidebarItem: SidebarItem
    sidebarVisible: boolean
    onSelect: () => void
}

const SidebarListItemComponent = ({
                                      sidebarItem,
                                      sidebarVisible,
                                      onSelect
                                  }: SidebarListItemComponentProps) => {

    return (
        <ListItem key={sidebarItem.text} disablePadding sx={{display: 'block'}}>
            <div
                onClick={() => onSelect()}
                className={`group flex items-center mb-11 cursor-pointer ${sidebarItem.selected() ? "text-main-color" : "text-gray-400"}`}
            >
                  <span
                      className={`flex-shrink-0 group-hover:text-main-color ${sidebarItem.selected() ? "text-main-color" : "text-gray-400"} ${sidebarVisible ? "ml-5" : "ml-5"}`}>
                    {sidebarItem.icon}
                  </span>
                {sidebarVisible ?
                    (
                        <span
                            className={`ml-2 whitespace-nowrap group-hover:text-main-color ${sidebarItem.selected() ? "text-main-color" : "text-gray-400"}`}>
                                {sidebarItem.text}
                            </span>)
                    : (
                        <span
                            className={`absolute top-8 left-0 right-0 text-xs whitespace-nowrap text-center group-hover:text-main-color ${sidebarItem.selected() ? "text-main-color" : "text-gray-400"}`}>
                              {sidebarItem.text}
                            </span>
                    )
                }
            </div>
        </ListItem>
    )
}

export default SidebarListItemComponent