import React, { useState } from 'react';
import {PopupComponent} from "../../components/modals/PopupComponent";

const PowerUpFeedbackPopup: React.FC = () => {
    const [isPopupVisible, setPopupVisible] = useState(false);

    const openPopup = () => {
        setPopupVisible(true);
    };

    const closePopup = () => {
        setPopupVisible(false);
    };

    return (
        <div>
            <button
                onClick={openPopup}
                className="text-blue-500 underline hover:text-blue-700"
            >
                Gi feedback
            </button>

            <PopupComponent closePopup={closePopup} isVisible={isPopupVisible}>
                <iframe
                    title="Feedback Form"
                    src="https://form.jotform.com/242263837325054"
                    width="100%"
                    height="500px"
                    frameBorder="0"
                    allowFullScreen
                ></iframe>
            </PopupComponent>
        </div>
    );
};

export default PowerUpFeedbackPopup;
