import Heading2 from "../../../components/text/Heading2";
import HeadingDescriptor from "../../../components/text/HeadingDescriptor";
import DevNote from "../../../components/dev/DevNote";
import MainButton from "../../../components/buttons/MainButton";
import React, {useEffect, useState} from "react";
import axios from "axios";
import {getBackendURL} from "../../../utils/EnvironmentsManager";
import toast from "react-hot-toast";
import {useStoreProvider} from "../../../providers/StoreProvider";
import {ArrowDownIcon} from "@heroicons/react/24/solid";
import VideoPopup from "../../../VideoPopup";

interface UploadUpdatedThemeToShopifyStepProps {
    visible: boolean
    jobId?: string
    onNextPressed: () => void
}

export const UploadUpdatedThemeToShopifyStep = ({jobId, visible, onNextPressed}: UploadUpdatedThemeToShopifyStepProps) => {
    const [job, setJob] = useState<any>(undefined)
    const {reloadStores} = useStoreProvider()
    const [themeDownloaded, setThemeDownloaded] = useState(false)

    useEffect(() => {
        axios.get(`${getBackendURL()}/api/v1/theme/job/${jobId}`).then(result => {
            setJob(result.data)
        }).catch(error => {
            toast.error("En feil har oppstått")
        })
    }, []);

    const downloadThemePressed = () => {
        if (themeDownloaded) {
            toast.error("Du har allerede lastet ned oppdatert tema")
            return
        }
        window.open(
            `${getBackendURL()}/api/v1/theme/download/${jobId}`,
            '_blank'
        );

        setThemeDownloaded(true)

        setTimeout(() => {
            reloadStores && reloadStores()
        }, 2000)
    }

    return (
        <>
            {visible && (
                <>
                    <Heading2>3. Last opp ditt oppdaterte tema til Shopify</Heading2>
                    <HeadingDescriptor leftAligned={true} withoutMargin={true}>
                        Temaet ditt er nå oppdatert til nyeste versjon. Last det opp til Shopify igjen for å ta det i
                        bruk.
                    </HeadingDescriptor>
                    <VideoPopup
                        videoSrc={"https://fast.wistia.com/embed/medias/8rnpoa8ln3"}
                        infoText="Se videoen om du trenger hjelp"
                    />
                    <p className={"mb-2 mt-5"}>
                        1. Naviger til Shopify → Online Store.
                    </p>
                    <img src={"/enthemed-tutorial/update-theme/pre-download-tutorial1.jpg"}/>

                    <p className={"mt-10 mb-2"}>
                        2. Klikk “Add theme” → Deretter “Upload Zip file”.
                    </p>
                    <img src={"/enthemed-tutorial/update-theme/post-download-tutorial1.jpg"}/>

                    <p className={"mt-10 mb-2"}>
                        3. Last ned filen under, og last den opp i Shopify-feltet.
                    </p>

                    {job != undefined && (
                        <button
                            className={"flex flex-row items-center gap-x-2 py-2 px-4 shadow-md text-white rounded-md mb-4 " + (themeDownloaded ? "bg-gray-400" : "bg-gradient-to-br from-main-gradient-first to-main-gradient-second")}
                            onClick={downloadThemePressed}
                        >
                            <ArrowDownIcon className={"w-5"}/>
                            Last ned oppdatert tema
                        </button>
                    )}

                    <img src={"/enthemed-tutorial/update-theme/post-download-tutorial2.jpg"}/>

                    <p className={"mt-10 mb-2"}>
                        4. Når det oppdaterte teamet er lastet opp, trykk “Publiser” for å ta i bruk den oppdaterte
                        versjonen.
                    </p>
                    <img src={"/enthemed-tutorial/update-theme/post-download-tutorial3.jpg"}/>


                    {job != undefined && (
                        <div className={"w-full flex flex-row justify-center mt-5"}>
                            <MainButton
                                onClickEvent={onNextPressed}
                            >
                                Fullført
                            </MainButton>
                        </div>
                    )}
                </>
            )}
        </>
    )
}