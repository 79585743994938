import React, {useEffect, useState} from 'react';
import {useForm, FormProvider, SubmitHandler} from 'react-hook-form';
import axios from 'axios';
import {DeliveryDateFormData} from "../types/DeliveryDate";
import {getBackendURL} from "../../../utils/EnvironmentsManager";
import toast from "react-hot-toast";
import InputTextField from "../../../components/input-fields/InputTextField";
import MainButton from "../../../components/buttons/MainButton";
import {toastError} from "../../../utils/ErrorHandlerUtils";
import DestructiveButton from "../../../components/buttons/DestructiveButton";
import {usePowerUpsProvider} from "../../../providers/PowerUpsProvider";
import {DeliveryDateSettings} from "../../../types/DeliveryDateSettings";
import Spinner from "../../../components/loading/Spinner";

const DeliveryDateForm = () => {
    const {closeCustomizeMenu, disablePowerUp, setIsSendingData, isSendingData} = usePowerUpsProvider()
    const [settings, setSettings] = useState<DeliveryDateSettings>()
    const methods = useForm<DeliveryDateFormData>();


    useEffect(() => {
        axios.get<DeliveryDateSettings>(getBackendURL() + '/api/v1/power-ups/delivery-date/settings')
            .then(result => {
                setSettings(result.data);
            })
            .catch(error => {
                console.error('Error fetching delivery date settings', error);
            });
    }, []);

    const onSubmit: SubmitHandler<DeliveryDateFormData> = async (values) => {
        if (isSendingData) {
            return
        }
        setIsSendingData(true);

        const payload = {
            minDays: values.minDays,
            maxDays: values.maxDays
        };

        axios.post(getBackendURL() + "/api/v1/power-ups/delivery-date/settings", payload)
            .then(result => {
                toast.success('Leveringsdato innstillinger er lagret');
                closeCustomizeMenu();

            })
            .catch(error => {
                toastError(error)
            })
            .finally(() => {
                setIsSendingData(false)
            })
    };

    return (
        <FormProvider {...methods}>
            {settings !== undefined ? (
                <form onSubmit={methods.handleSubmit(onSubmit)} className="flex flex-col items-center">
                    <div className="flex items-center space-x-2 mt-5">
                        <label htmlFor="minDays" className="text-sm font-medium">Levering mellom</label>
                        <InputTextField
                            name="minDays"
                            type="number"
                            value={settings && String(settings!.minDays)}
                            label="f.eks 3 dager"
                            placeholder="2 dager"
                            validation={{required: true, valueAsNumber: true, min: 0}}
                        />
                        <span className="text-sm font-medium">og</span>
                        <InputTextField
                            name="maxDays"
                            type="number"
                            label="f.eks 5 dager"
                            value={settings && String(settings!.maxDays)}
                            placeholder="5 dager"
                            validation={{required: true, valueAsNumber: true, min: 0}}
                        />
                        <span className="text-sm font-medium">dager.</span>
                    </div>
                    <div className="mt-5 flex justify-center gap-5">
                        <DestructiveButton onClickEvent={disablePowerUp}>Deaktiver</DestructiveButton>
                        <MainButton>Lagre</MainButton>
                    </div>
                </form>
            ) : (
                <Spinner className="flex justify-center"/>
            )}
        </FormProvider>
    );
};

export default DeliveryDateForm;
