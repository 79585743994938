import ConnectToShopifyStep from "../onboardings/ConnectToShopifyStep";
import IntegrateWithShopifyStep from "../onboardings/IntegrateWithShopifyStep";
import * as React from "react";
import InputStoreNameStep from "../onboardings/InputStoreNameStep";
import ConfigureAppStep from "../onboardings/ConfigureAppStep";
import BuyExtraStoreLicenseStep from "./components/BuyExtraStoreLicenseStep";
import {useEffect} from "react";
import axios from "axios";
import {getBackendURL} from "../../utils/EnvironmentsManager";
import {useStoreProvider} from "../../providers/StoreProvider";
import Spinner from "../../components/loading/Spinner";
import Heading1 from "../../components/text/Heading1";
import WelcomeStep from "../onboardings/WelcomeStep";
import SubscriptionSucceedStep from "../onboardings/SubscriptionSucceedStep";
import SetUpAppStep from "../onboardings/SetUpAppStep";
import FinalStep from "../onboardings/FinalStep";
import {useNavigate} from "react-router";
import {useSearchParams} from "react-router-dom";
import {toast} from "react-hot-toast";

const AddStorePage = () => {
    const {reloadStores} = useStoreProvider()
    const navigate = useNavigate();
    const [step, setStep] = React.useState<number>(-1);
    const [searchParams, setSearchParams] = useSearchParams()

    useEffect(() => {
        setTimeout(() => {
            checkLicenses()
        }, 1500)
    }, []);

    const checkLicenses = () => {
        axios.get(getBackendURL() + "/api/v1/license/available").then(result => {
            const extraLicenseAvailable = result.data
            if (extraLicenseAvailable) {
                setStep(1);
            }else {
                setStep(0);
            }
        }).catch(error => {
            console.log(error);
        })
    };

    const userPressedNext = () => {
        setStep(step + 1);
    }

    const userPressedFinalNext = () => {
        reloadStores && reloadStores()
        navigateBack()
        toast.success("Nettbutikken er nå lagt til")
    }

    const navigateBack = () => {
        if (searchParams.has("redirectUri")) {
            navigate(searchParams.get("redirectUri")!)
        }else {
            navigate("/");
        }
    }

    return (
        <div className={"absolute -z-10 top-0 left-0 flex flex-col w-screen h-screen justify-center items-center px-10 md:pl-28 md:pr-8 lg:px-0"}>
            <div className={"flex flex-col items-center"}>
                {step == -1 && (
                    <>
                        <Heading1>Et øyeblikk</Heading1>
                        <Spinner className={"mt-5"} />
                    </>
                )}
                <BuyExtraStoreLicenseStep onNextPressed={userPressedNext} stepVisible={step == 0}/>
                <SubscriptionSucceedStep onNextPressed={userPressedNext} stepVisible={step == 1}/>
                <InputStoreNameStep onNextPressed={userPressedFinalNext} stepVisible={step == 2}/>
            </div>
        </div>
    )
}

export default AddStorePage;