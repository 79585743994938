import React, {useEffect, useState} from 'react';
import Heading2 from "../../components/text/Heading2";
import ThemeGridElement from "./components/ThemeGridElement";
import ThemeDetailsPopup from "./components/ThemeDetailsPopup";
import SuccessPopup from "./components/DownloadThemeSucessPopup";
import {getBackendURL} from "../../utils/EnvironmentsManager";
import {ThemeDTO} from "../../types/ThemeDTO";
import {useThemes} from "../../providers/ThemesProvider";
import {useStoreProvider} from "../../providers/StoreProvider";
import RegisterOrIntegrateStorePopup from "../../components/modals/RegisterOrIntegrateStorePopup";
import ThemeInstallTutorialPopup from "./components/ThemeInstallTutorialPopup";
import theme from "tailwindcss/defaultTheme";
import {useNavigate} from "react-router";
import Callout from "../../components/text/Callout";
import HeadingDescriptor from "../../components/text/HeadingDescriptor";
import MainButton from "../../components/buttons/MainButton";
import UnknownThemePopup from "./components/UnknownThemePopup";

const ThemePage = () => {
    const navigate = useNavigate();

    const {themes} = useThemes()
    const {store, showRegisterStorePrompt, registerStorePromptVisible, reloadStores} = useStoreProvider()
    const [isDetailsPopupVisible, setIsDetailsPopupVisible] = useState(false);
    const [isSuccessPopupVisible, setIsSuccessPopupVisible] = useState(false);
    const [isTutorialVisible, setIsTutorialVisible] = useState(false);
    const [selectedTheme, setSelectedTheme] = useState<ThemeDTO | null>(null);

    const handleDetailsButtonClick = (theme: ThemeDTO) => {
        setSelectedTheme(theme);
        setIsDetailsPopupVisible(true);
    };

    const handleDownloadClick = (selectedTheme: ThemeDTO) => {
        // har man registrert nettbutikken sin enda?
        if (!store) {
            showRegisterStorePrompt && showRegisterStorePrompt("Registrer nettbutikk", (
                <>
                    For å kunne laste ned et Enthemed tema, må du registrere nettbutikken din først.
                </>
            ));
            return
        }

        window.open(
            getBackendURL() + "/api/v1/theme/" + selectedTheme?.id + "/download",
            '_blank'
        );

        setTimeout(() => reloadStores && reloadStores(), 2000)
        setIsDetailsPopupVisible(false);
        setIsSuccessPopupVisible(true);
    };

    const handleDetailsPopupClose = () => {
        setIsDetailsPopupVisible(false);
    };

    const handleSuccessPopupClose = () => {
        setIsSuccessPopupVisible(false);
    };

    return (
        <div className="container mx-auto px-10 py-5">
            <Heading2 className="text-left text-4xl mt-3">Nettbutikk temaer</Heading2>
            <p className="text-gray-500 text-left text-md mt-2">
                Designet av eksperter. Bygg og lanser din nettbutikk raskt og effektivt.
            </p>
            <div className="border-b border-gray-200 my-5 mb-10"></div>

            {store?.themeRelease && store.themeRelease.theme.id === "unknown" && (
                <UnknownThemePopup />
            )}

            <div className="grid gap-y-10 2xl:grid-cols-2 2xl:gap-x-4">
                {themes && themes.filter(theme => theme.id !== "unknown").map((theme, index) => (
                    <ThemeGridElement
                        key={index}
                        theme={theme}
                        onButtonClick={() => handleDetailsButtonClick(theme)}
                        showTutorialClicked={() => setIsTutorialVisible(true)}
                    />
                ))}
            </div>
            <div className="border-b border-gray-200 pb-10"></div>

            {!registerStorePromptVisible && selectedTheme && (
                <ThemeDetailsPopup
                    handleCancelClick={handleDetailsPopupClose}
                    isVisible={isDetailsPopupVisible && selectedTheme && !registerStorePromptVisible}
                    handleDownloadClick={() => handleDownloadClick(selectedTheme)}
                    imageSrc={selectedTheme.imageSrc}
                    title={selectedTheme.title}
                    previewLink="#"
                    selectedTheme={selectedTheme}
                />
            )}

            <SuccessPopup
                handleCancelClick={handleSuccessPopupClose}
                isVisible={isSuccessPopupVisible}
            />

            <ThemeInstallTutorialPopup
                handleCancelClick={() => setIsTutorialVisible(false)}
                isVisible={isTutorialVisible}
            />
        </div>
    )
}

export default ThemePage;
