// GeneralSettingsPage.tsx
import React, { useEffect, useState } from 'react';
import Heading1 from "../../components/text/Heading1";
import HeadingDescriptor from "../../components/text/HeadingDescriptor";
import { AccountFragment } from "./components/AccountFragment";
import { StoreSettingsFragment } from "./components/StoreFragment";
import { CustomAppFragment } from "./components/CustomAppFragment";
import SettingsFragment from "./components/SettingsFragment";
import Settings from "./components/Settings";

const GeneralSettingsPage = () => {
    const settingsPages: SettingsFragment[] = [AccountFragment, StoreSettingsFragment, CustomAppFragment];
    const [isMobile, setIsMobile] = useState<boolean>(false);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        handleResize();
        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <div className={"container mx-auto w-auto p-4 md:p-10"}>
            <Heading1>Innstillinger</Heading1>
            <HeadingDescriptor withoutMargin={true}>Her kan du se innstillingene dine</HeadingDescriptor>
            <Settings pages={settingsPages} isMobile={isMobile} />
        </div>
    );
};

export default GeneralSettingsPage;