import {PowerUpsCustomizeInfo} from "./types/PowerUpsCustomizeInfo";
import SuggestedProductsForm from "../../power-ups/suggested-product/SuggestedProductsForm";
import {PowerUpDTO} from "../../types/PowerUpDTO";
import DeliveryDateForm from "../../power-ups/delivery-date/components/DeliveryDateForm";
import PreOrderForm from "../../power-ups/pre-order/components/PreOrderForm";
import InactiveTabForm from "../../power-ups/inactive-tab-warning/InactiveTabForm";
import CookieBannerForm from "../../power-ups/cookie-banner/CookieBannerForm";

export const powerUpCanBeCustomized = (powerUpDTO: PowerUpDTO) => {
    let fields = getSettingsForPowerUp(powerUpDTO.id)
    return fields != null
}

export const getPowerUpCustomizeInfoForPowerUp = (powerUpDTO: PowerUpDTO) => {
    let fields = getSettingsForPowerUp(powerUpDTO.id)
    if (fields == null) {
        return null
    }

    let object: PowerUpsCustomizeInfo = {
        fields: fields,
        powerUpDTO: powerUpDTO
    }
    return object
}

/**************************************************************************************/
/******************* THIS PART MUST BE CUSTOMIZED FOR EACH POWER-UP *******************/
/**************************************************************************************/

const getSettingsForPowerUp = (powerUpId: string) => {
    switch (powerUpId) {
        case "suggestedProduct": return <SuggestedProductsForm/>;
        case "deliveryDate": return <DeliveryDateForm/>;
        case "preOrder": return <PreOrderForm/>;
        case "inactiveTabWarning": return <InactiveTabForm />
        case "cookieBanner": return <CookieBannerForm />
        default: return null;
    }
}

export const getPreviewForPowerUp = (powerUpId: string) => {
    switch (powerUpId) {
        case "suggestedProduct": return <img src={"/power-ups/suggested-product/preview.jpg"}/>;
        case "deliveryDate": return <img src={"/power-ups/delivery-date/preview.jpg"}/>;
        case "oneClickCheckout": return <img src={"/power-ups/one-click-checkout/preview.jpg"}/>;
        case "preOrder": return <img src={"/power-ups/pre-order/preview.jpg"}/>;
        case "inactiveTabWarning": return <img src={"/power-ups/inactive-tab-warning/preview.jpg"}/>;
        case "cookieBanner": return <img src={"/power-ups/cookie-banner/preview.jpg"}/>;
    }
}