import Heading1 from "../../components/text/Heading1";
import MainButton from "../../components/buttons/MainButton";
import HeadingDescriptor from "../../components/text/HeadingDescriptor";
import {useState} from "react";
import axios from "axios";
import {getBackendURL} from "../../utils/EnvironmentsManager";

const LicenseInvalidPage = () => {

    const [isLoading, setIsLoading] = useState(false)

    const resubscribePressed = () => {
        setIsLoading(true)
        axios.post(getBackendURL() + "/api/v1/license/resubscribe").then(result => {
            console.log(result)
            if (result.data) {
                window.location.href = result.data
            }
        })
    }

    return (
        <>
            <div className={"h-screen flex justify-center items-center flex-col"}>
                <Heading1>Ditt abonnement er ikke aktivt</Heading1>
                <HeadingDescriptor>Frykt ikke! Du kan aktivere abonnementet igjen med knappen under</HeadingDescriptor>
                <MainButton onClickEvent={resubscribePressed} showProcessingAnimation={isLoading}>Abonner</MainButton>
            </div>
        </>
    )
}

export default LicenseInvalidPage;