import React from 'react';
import {NavLink} from "react-router-dom";

interface LinkTextProps {
    children: React.ReactNode;
    href: string;
    className?: string;
}

const LinkText: React.FC<LinkTextProps> = ({ children, href, className }) => {
    return (
        <NavLink to={href} className={"ml-1 font-semibold leading-6 text-indigo-600 hover:text-indigo-500 cursor-pointer " + className}>
            {children}
        </NavLink>
    );
};

export default LinkText;