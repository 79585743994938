import React, {ReactElement} from 'react';
import Spinner from "../../../components/loading/Spinner";

interface suksessklubbButtonProps {
    onClickEvent?: ()=>void
    children?: any
    showProcessingAnimation?:boolean
    className?: string
    icon?: ReactElement<any, any>
    doNotSubmit?: boolean
    disabled?: boolean
}

const SuksessklubbButton = (props:suksessklubbButtonProps) => {
    const enabledStyling = "bg-orange-500 hover:bg-orange-400"
    const disabledStyling = "bg-gray-400 cursor-not-allowed"

    const buttonClicked = () => {
        if (!props.disabled) {
            props.onClickEvent && props.onClickEvent()
        }
    }


    return (
        <button
            type={props.doNotSubmit || props.onClickEvent || (props.disabled === true) ? "button" : "submit"}
            className={`flex justify-center transition-all text-white py-2 px-6 rounded-full shadow-sm ${props.disabled ? disabledStyling : enabledStyling} ${props.showProcessingAnimation ? 'opacity-50 cursor-not-allowed' : ''}` + props.className}
            disabled={props.showProcessingAnimation || props.disabled}
            onClick={props.onClickEvent ? buttonClicked : undefined}>
            {props.icon} {props.icon ? <>&nbsp;&nbsp;</> : ""}
            {props.showProcessingAnimation ?
                <Spinner scale={25}/> : props.children
            }
        </button>
    )
}

export default SuksessklubbButton;