import React from "react";
import {PopupComponent} from "../../../components/modals/PopupComponent";
import Heading1 from "../../../components/text/Heading1";
import HeadingDescriptor from "../../../components/text/HeadingDescriptor";

interface ThemeInstallTutorialPopupProps {
    handleCancelClick: () => void;
    isVisible: boolean;
}

const ThemeInstallTutorialPopup = ({ handleCancelClick, isVisible }: ThemeInstallTutorialPopupProps) => {
    return (
        <PopupComponent closePopup={handleCancelClick} isVisible={isVisible}>
            <div className="text-center">
                <Heading1 className="text-2xl font-bold mb-4">Installasjon av tema</Heading1>
                <div className="relative w-full h-0 pb-[56.25%] mb-4">
                    <iframe
                        src="https://fast.wistia.com/embed/medias/tchpiqcpyy"
                        title="Enthemed Video"
                        className="wistia_swatch absolute top-0 left-0 w-full h-full"
                        allowFullScreen
                    ></iframe>
                </div>

            </div>
        </PopupComponent>
    );
};

export default ThemeInstallTutorialPopup;
